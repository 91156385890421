import OpenAI from "openai";

import { TaskType } from '@heygen/streaming-avatar';

import { updateAIMesssageToChat } from "../SoulMachines";

let prev_response ="";

export class OpenAIAssistant {
  constructor(apiKey) {
    this.client = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
    this.assistant = null;
    this.thread = null;
  }

  async initialize(instructions) {
    // Create an assistant
    this.assistant = await this.client.beta.assistants.create({
      name: "Sofia",
      instructions,
      tools: [{ type: "code_interpreter" }],
      model: "gpt-4-turbo",
    });

    // Create a thread
    this.thread = await this.client.beta.threads.create();
  }


  async getResponse(userMessage, heygenAvatar) {
    return new Promise((resolve, reject) => {
      let sentenceBuffer = "";
      // eslint-disable-next-line no-unused-vars
      let fullResponse = "";
  
      if (!this.assistant || !this.thread) {
        reject(new Error("Assistant not initialized. Call initialize() first."));
        return;
      }
  
      // Add user message to thread
      this.client.beta.threads.messages.create(this.thread.id, {
        role: "user",
        content: userMessage,
      });
  
      this.client.beta.threads.runs.stream(this.thread.id, {
        assistant_id: this.assistant.id
      })
      .on('textCreated', (text) => console.log('\nassistant > '))
      .on('textDelta', async (textDelta, snapshot) => {
        sentenceBuffer += textDelta.value;
        if (/[.!?]$/.test(sentenceBuffer.trim()) && !/\d\.$/.test(sentenceBuffer.trim())) {
          const sentence = sentenceBuffer.trim();

          if (sentence.toLowerCase() !== "that is correct." && sentence.toLowerCase() !== "that is incorrect.") {
            updateAIMesssageToChat(prev_response + sentence);
            prev_response = "";
          }
          else{
            prev_response = sentence + " ";
          }

          heygenAvatar.speak({
            text: sentenceBuffer.trim(),
            taskType: TaskType.REPEAT,
          });
  
          fullResponse += sentenceBuffer;
          sentenceBuffer = ""; 
        }
      })
      .on('toolCallCreated', (toolCall) => console.log(`\nassistant > ${toolCall.type}\n\n`))
      .on('toolCallDelta', (toolCallDelta, snapshot) => {
        if (toolCallDelta.type === 'code_interpreter') {
          if (toolCallDelta.code_interpreter.input) {
            console.log("This is toolCallDelta: " + toolCallDelta.code_interpreter.input);
          }
          if (toolCallDelta.code_interpreter.outputs) {
            toolCallDelta.code_interpreter.outputs.forEach(output => {
              if (output.type === "logs") {
                console.log(`This is output log: \n${output.logs}\n`);
              }
            });
          }
        }
      })
      .on('end', () => {
        console.log("Streaming complete!");
        resolve(true); // Resolving with true
      })
      .on('error', (err) => {
        console.error("Error during streaming:", err);
        reject(err); // Rejecting with error if streaming fails
      });
    });
  }
}