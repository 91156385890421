import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './NavBar.css';

const InstructorNavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.instructor-navbar')) {
        setIsMenuOpen(false);
      }
    };
    if (isMenuOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <nav className="instructor-navbar">
      <div className="navbar-header">
        <i className="fa-solid fa-bars menu-icon" onClick={toggleMenu}></i>
      </div>
      <ul className={`instructor-navbar-list ${isMenuOpen ? 'open' : ''}`}>
        <li>
          <NavLink
            to="/manage"
            end
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/manage/courses"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Courses
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/manage/feedback"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Feedback
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/manage/help"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Help
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default InstructorNavBar;
