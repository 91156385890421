import React, { createContext, useState, useEffect } from 'react';

export const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode === 'true';
  });

  const toggleDarkMode = async () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      sessionStorage.setItem('darkMode', newMode);
      return newMode;
    });
    const user_id = sessionStorage.getItem('id');
    const sessionMode = sessionStorage.getItem('darkMode');
    console.log(sessionMode);
    let dark;
    if (sessionMode === 'true') {
      dark = 'True';
    } else if (sessionMode === 'false') {
      dark = 'False';
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/toggle-darkmode/${user_id}`,
        {
          method: 'POST',
          body: JSON.stringify({
            darkmode: dark,
          }),
        }
      );
      console.log(response);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
