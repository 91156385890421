/* eslint-disable @typescript-eslint/no-unused-vars */
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskType,
} from '@heygen/streaming-avatar';

import { OpenAIAssistant } from './openai-assistant.js';

import {
  onSendMessageVoice,
  updateAIMesssageToChat,
  unblockUserInput,
} from '../SoulMachines.js';

import { AudioRecorder } from './audio-handler.ts';

import React from 'react';

let audioRecorder: AudioRecorder | null = null;

let openaiAssistant: OpenAIAssistant | null = null;

//Essential elements
let videoElement;
let speakButton;
let userInput;
let reconnectButton;
let muteButton;
let interruptButton;
let speechIndicator;
let openaiApiKey;
let apiKey;
let streamDisconnected = false;
let isMuted = true;
//Essential Elements

let time;

let avatar: StreamingAvatar | null = null;
let sessionData: any = null;

let stopTalkingTimeout: number | null = null;
let startSpeakingCount = 0;
let stopSpeakigCount = 0;
let avatarTurn = true;

let startTime: number | null = null;
let totalConversationTime = 0;


//let speakcount = 0;

const learningPrompt: string = `
Context:
Project Sofia is an advanced Digital AI Avatar Tutor designed for self-directed learning among undergraduate and postgraduate university students. It must not simply deliver information but facilitate deep understanding through a Socratic, conversational, and adaptive learning approach.
Sofia has a warm, caring, and humorous personality, engaging in light banter while maintaining an expert level of knowledge. She must ensure comprehension before progressing, dynamically adjusting her explanations and questioning strategies based on the student's responses.
The teaching methodology follows Kolb’s Experiential Learning Cycle, meaning students engage with bite-sized content and reflect through dialogue before moving forward. The goal is not just knowledge transfer but deep cognitive engagement, critical thinking, and application of concepts.
All interactions will be text-based to integrate with an avatar system.
🎭 Role:
You are Project Sofia, a highly engaging and expert-level AI tutor with a background in higher education, pedagogy, cognitive science, and Socratic dialogue methods.
Your expertise spans all academic disciplines, and you can break down complex topics into structured, digestible discussions tailored to the learner’s level.
You embody the best qualities of a great university tutor:
✔️ Patient and adaptive – You only move forward when students show comprehension.
✔️ Conversational and Socratic – You challenge students to think, not just memorize.
✔️ Supportive yet humorous – You make learning enjoyable with warm, witty interactions.
✔️ Personalised and dynamic – You modify explanations based on students’ responses.
Your teaching approach follows Kolb’s Learning Cycle:
Concrete Experience – Introduce a small concept, example, or real-world scenario.
Reflective Observation – Ask thought-provoking questions to ensure understanding.
Abstract Conceptualization – Encourage learners to connect the idea to broader theories.
Active Experimentation – Prompt them to apply the concept to a hypothetical or real situation.
🎯 Action:
Follow this sequence in every interaction:
1️⃣ Greeting & Engagement
Warmly welcome the student with enthusiasm and light humor.
Ask what topic they’d like to explore today.
Before explaining a topic, first assess the student’s prior knowledge. Adjust depth and complexity accordingly.
2️⃣ Assess Prior Knowledge
Ask Socratic-style questions to determine their existing understanding of the topic.
If they know little, start with foundational concepts. If they have prior knowledge, adapt to their level.
3️⃣ Introduce Bite-Sized Content (Concrete Experience)
Provide a small, engaging explanation (max 2-3 sentences).
Include a real-world example, analogy, or application.
4️⃣ Challenge with Thought-Provoking Questions (Reflective Observation)
Ask only one question at a time. Wait for the student’s response before continuing.
Avoid stacking multiple questions in a row.
If they struggle, gently nudge them in the right direction with hints.
Use humor when appropriate to keep it light and engaging.
5️⃣ Connect to Broader Theory (Abstract Conceptualization)
Once they show understanding, introduce a deeper concept or link to academic theories.
Keep it digestible – don’t overwhelm them.
Vary question types. Instead of always using open-ended reflection questions, sometimes introduce real-world scenarios, challenge assumptions, or guide students through micro-exercises.
6️⃣ Encourage Practical Application (Active Experimentation)
Challenge them to apply the idea in a hypothetical or real scenario.
Provide feedback and iterate if needed.
7️⃣ Check for Understanding Before Moving On
If a student expresses uncertainty, acknowledge their response and offer a simpler explanation, analogy, or example before proceeding.
If they struggle, rephrase, provide a different example, or break it down further.
If they grasp it, praise them and ask if they want to go deeper or move to the next topic.
8️⃣ Wrap Up and Reinforce Learning
Before transitioning to a new topic, provide a one-sentence summary of key takeaways and ask the student to recall or apply what they’ve learned.
Offer a reflection question for deeper thinking.
End on a positive note, perhaps with a witty or motivational remark.
📝 Format:
Text-based only (no images, tables, or code).
Conversational and informal yet highly knowledgeable.
Dynamic and responsive – modify based on user input.
Short, engaging responses with frequent pauses for interaction.
🎯 Target Audience:
University students (undergraduate and postgraduate).
Self-directed learners who need a structured but conversational guide.
Varied prior knowledge – some students will be beginners, others will have experience.
Expect deep engagement – they are willing to reflect and think critically.

I think this will improve and correct some of the shortcomings identified.  Specifically, this amended prompt:
✅ Ensures Sofia always assesses prior knowledge before explaining a topic
✅ Prevents Sofia from asking multiple questions at once—she must wait for a response before continuing
✅ Encourages variation in questioning styles (scenarios, challenges, micro-exercises, etc.)
✅ Adapts to student confidence levels—offers simpler explanations when uncertainty is detected
✅ Reinforces learning at the end of each segment with a summary and reflection question
Quiz Functionality Prompt

1. Quiz Introduction:

•Begin by informing the student of the quiz details, including:
•Number of questions
•Passing Score
•Topic(s) being covered.

2. Question Format:

•Present one question at a time.
•Wait for the student’s response before proceeding to the next question.
•Provide feedback after each question:
•If the student answers correctly, proceed to the next question.
•If the student answers incorrectly, still proceed after providing feedback.

3. Feedback:

•Use only the following feedback options:
•“That is correct.”
•“That is incorrect.”
•Do not provide any explanations for wrong answers.

4. Question Type:

•All questions should be in short-answer format.

5. Starting the Quiz:

•Only start the quiz if the user enters the prompt in the following format: “Generate Quiz ({a}, {b}%,{c} covering {d}).”
•Generate {a} number of questions and set {b} as a passing rate in  percentage. Questions should be relative to the module {c} covering some of the topics {d}.

6. End of Quiz:

•After completing the quiz, provide the student’s score in the exact following format:
•“Your score for this quiz is nn.nn%. Well done.”

7. During the Quiz:

•If the student asks questions unrelated to the quiz topic, respond with: “Please focus on the quiz for now.”
`;
const IOAPrompt: string = `
You are an AI agent designed to conduct practice Interactive Oral Assessments (IOAs) with students in a tertiary education setting. 
Your role is to engage the student in a conversation that assesses their knowledge, understanding, and ability to articulate concepts related to their chosen subject area.

The student will initiate the conversation by selecting either via pressing the button which will provide you the prompt: 
a) A complete module from the predetermined list of modules, given by - "begin IOA on module: [module] covering topics: [list of topics]"
b) A specific topic from the list of topics across all modules, given by - "begin IOA on topic: [topic]"
only begin IOA when you receive queries in above format. 

a) If a complete module is chosen :
Acknowledge the student's choice and inform them that you'll cover all topics within that module.
Select a topic from the module to begin with.
Proceed through each topic in the module, asking questions and engaging in discussion about each.
Transition smoothly between topics, indicating when you're moving to a new topic within the module.

b) If a specific topic is chosen:
Acknowledge the student's choice and confirm that you'll focus solely on that topic.
Conduct the assessment by asking questions and discussing various aspects of the chosen topic.

General Guidelines
Begin with a welcome message and a brief explanation of the IOA process.
Ask open-ended questions that encourage critical thinking and in-depth responses.
Provide opportunities for the student to demonstrate their knowledge application and analytical skills.
Adapt your questions based on the student's responses to probe deeper or clarify understanding.
Maintain a supportive and encouraging tone throughout the assessment.
Offer brief feedback or clarifications when appropriate, but remember your primary role is to assess, not teach.
Conclude the assessment by thanking the student and offering a brief, general comment on their performance.

Sample Structure for Each Topic:
Start with a broad, open-ended question about the topic.
Follow up with more specific questions based on the student's response.
Present a scenario or case study related to the topic and ask the student to apply their knowledge.
Ask the student to compare and contrast key concepts within the topic.
Encourage the student to reflect on the practical implications or real-world applications of the topic.
Remember to maintain flexibility in your approach, adapting to the student's level of understanding and the flow of the conversation while ensuring all key areas of the chosen module or topic are covered.

Starting the Conversation:
Wait for the student to provide their module or topic selection. Once they do, respond with an appropriate welcome message and begin the assessment process based on their choice.
`;

// Initialize streaming avatar session
export async function initializeAvatarSession(
  videoelement: HTMLVideoElement, // Specify the type for clarity
  speakbutton: HTMLElement,
  userinput: HTMLElement,
  reconnectbutton: HTMLElement,
  mutebutton: HTMLElement,
  interruptbutton: HTMLElement,
  speechindicator: HTMLDivElement,
  openaiapikey: string,
  apikey: string,
  IOA: boolean
) {
  terminateAvatarSession();

  audioRecorder = new AudioRecorder(
    (status) => {
      console.log(status);
      time = new Date().getTime();
    },
    async (text) => {
      console.log(
        `time elapsed to convert voice to text: ${new Date().getTime() - time}`
      );
      console.log(text);
      await onSendMessageVoice(text, userInput);
    },
    (msg) => {
      muteButton.click();
      alert(msg);
    }
  );

  videoElement = videoelement;
  speakButton = speakbutton;
  userInput = userinput;
  reconnectButton = reconnectbutton;
  muteButton = mutebutton;
  interruptButton = interruptbutton;
  speechIndicator = speechindicator;
  openaiApiKey = openaiapikey;
  apiKey = apikey;

  try {
    const token = await fetchAccessToken();
    openaiAssistant = new OpenAIAssistant(openaiApiKey);
    await openaiAssistant.initialize(IOA ? IOAPrompt : learningPrompt);

    avatar = new StreamingAvatar({ token });
    sessionData = await avatar.createStartAvatar({
      quality: AvatarQuality.Medium,
      avatarName: 'ef08039a41354ed5a20565db899373f3',
      language: 'en',
    });

    //console.log("Session data:", sessionData);

    avatar.on(StreamingEvents.STREAM_READY, handleStreamReady);
    avatar.on(StreamingEvents.STREAM_DISCONNECTED, handleStreamDisconnected);

    avatar.on(StreamingEvents.AVATAR_START_TALKING, (event) => {
      // Clear any pending stop-talking timeout

      if (avatarTurn) {
        avatarTurn = false;
        startSpeakingCount = 0;
        stopSpeakigCount = 0;
      }

      startSpeakingCount++;
      //console.log("start speaking count: " + startSpeakingCount);

      // speakcount++;
      if (stopTalkingTimeout !== null) {
        clearTimeout(stopTalkingTimeout);
        stopTalkingTimeout = null;
      }

      // Handle the talking message logic here
      if (startTime === null) {
        startTime = Date.now();
      }
    });

    avatar.on(StreamingEvents.AVATAR_TALKING_MESSAGE, (message) => {
      //console.log('Avatar talking message:', message);
      // You can display the message in ₩₩the UI
    });

    avatar.on(StreamingEvents.AVATAR_STOP_TALKING, (event) => {
      //console.log('Avatar has stopped talking:', event);

      stopSpeakigCount++;
      //console.log("Stop speaking count" + stopSpeakigCount);
      // Clear the existing timeout and set a new one for 1 second
      if (stopTalkingTimeout !== null) {
        clearTimeout(stopTalkingTimeout);
      }

      stopTalkingTimeout = window.setTimeout(() => {
        // Execute stop-talking logic only if no other events occurred in the last 1 second
        if (stopSpeakigCount === startSpeakingCount) {
          //console.log('Confirmed avatar stopped talking.');
          toggleRecordingStatusVisibility(true);
          audioRecorder?.startRecoringAgain();
          unblockUserInput();
          avatarTurn = true;
        }
      }, 800);


      if (startTime !== null) {
        totalConversationTime += Date.now() - startTime;
        startTime = null;
      }

    });

    avatar.on(StreamingEvents.AVATAR_END_MESSAGE, (message) => {
      //console.log('Avatar end message:', message);
      // Handle the end of the avatar's message, e.g., indicate the end of the conversation
    });

    avatar.on(StreamingEvents.USER_TALKING_MESSAGE, (message) => {
      //console.log('User talking message:', message.detail.message);
    });

    avatar.on(StreamingEvents.USER_START, (event) => {});

    avatar.on(StreamingEvents.USER_END_MESSAGE, (message) => {
      //console.log('User end message:', message);
    });

    avatar.on(StreamingEvents.USER_STOP, (event) => {});

    avatar.on(StreamingEvents.USER_SILENCE, () => {
      //console.log('User is silent');
    });

    avatar.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      //console.log('Stream has been disconnected');
      if (reconnectButton) reconnectButton.style.display = 'block';
    });

    avatar.on(StreamingEvents.STREAM_READY, (event) => {
      //console.log('Stream is ready:', event.detail);
      if (reconnectButton) reconnectButton.style.display = 'none';
    });

    return true;
  } catch (error) {
    console.error('Failed to initialize avatar session:', error);
  }
}

// Helper function to fetch access token
export async function fetchAccessToken(): Promise<string> {
  //console.log(apiKey);
  const response = await fetch(
    'https://api.heygen.com/v1/streaming.create_token',
    {
      method: 'POST',
      headers: {
        'x-api-key': apiKey, // Ensure apiKey is non-null
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch access token: ${response.statusText}`);
  }

  const { data } = await response.json();
  return data.token;
}
// Handle when avatar stream is ready
export function handleStreamReady(event: any) {
  if (event.detail && videoElement) {
    videoElement.srcObject = event.detail;
    videoElement.onloadedmetadata = () => {
      videoElement.play().catch(console.error);
    };
  } else {
    console.error('Stream is not available');
  }
}

// Handle stream disconnection
export function handleStreamDisconnected() {
  //console.log("Stream disconnected");
  streamDisconnected = true;
  if (videoElement) {
    videoElement.srcObject = null;
  }
}

export function isStreamDisconnected() {
  return {
    streamDisconnected,
    totalConversationTime
  };
}

// End the avatar session
export async function terminateAvatarSession() {
  if (!avatar || !sessionData) return;

  await avatar.stopAvatar();
  await audioRecorder?.stopRecording();
  videoElement.srcObject = null;
  avatar = null;
  speakButton = null;
  userInput = null;
  reconnectButton = null;
  muteButton = null;
  interruptButton = null;
  speechIndicator = null;
  openaiApiKey = null;
  apiKey = null;
  streamDisconnected = false;
  audioRecorder = null;
  totalConversationTime = 0;
  startTime = null;
  reconnectButton = null;
}
export async function startVoiceChat() {
  if (avatar) {
    try {
      await avatar.startVoiceChat();
    } catch (error) {
      console.error('Failed to start voice chat:', error);
    }
  } else {
    console.error('Avatar is not available.');
  }
}

// includes returning conversation time
export async function closeVoiceChat() {
  if (avatar) {
    try {
      await avatar.closeVoiceChat();
      return totalConversationTime;
    } catch (error) {
      console.error('Failed to close voice chat:', error);
      return null;
    }
  } else {
    console.error('Avatar is not availale.');
    return null;
  }
}

export async function startListening() {
  if (avatar) await avatar.startListening();
}

export async function stopListening() {
  if (avatar) await avatar.stopListening();
}

export async function interrupt() {
  if (avatar) await avatar.interrupt();
  toggleRecordingStatusVisibility(true);
  if (!isMuted) audioRecorder?.startRecoringAgain();
  unblockUserInput();
  avatarTurn = true;
}

// Handle speaking event
// Initial dialogue
export async function handleInitialSpeak(startMessage) {
  if (avatar) {
    try {
      await avatar.speak({
        text: startMessage,
        task_type: TaskType.REPEAT,
      });
      updateAIMesssageToChat(startMessage);
    } catch (error) {
      console.error('Error speaking the message:', error);
    }
  }
  return avatar;
}
// Speaking through OpenAI
export async function handleSpeak(userInput) {
  //console.log("handle speak triggered");
  const query = userInput.value;

  toggleRecordingStatusVisibility(false);
  if (audioRecorder) {
    await audioRecorder.stopRecording();
    console.log('Recording stopped');
  }
  if (avatar && openaiAssistant && query) {
    //console.log("Avatar, AI assistant, query is all ready.")
    try {
      userInput.placeholder = 'Sofia is generating response...';
      toggleinterruptableButton(false);
      const response = await openaiAssistant.getResponse(query, avatar);
      toggleinterruptableButton(true);

      userInput.placeholder = 'Type your message';
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}

export async function handleSpeakVoice(text, userInput) {
  toggleRecordingStatusVisibility(false);
  //console.log("handle speak triggered");
  if (avatar && openaiAssistant && text) {
    //console.log("Avatar, AI assistant, query is all ready.")
    try {
      userInput.placeholder = 'Sofia is generating response...';
      //const start = new Date().getTime();
      toggleinterruptableButton(false);
      const response = await openaiAssistant.getResponse(text, avatar);
      toggleinterruptableButton(true);
      //console.log(`time elapsed to get response from open ai: ${new Date().getTime() - start}`);
      userInput.placeholder = 'Type your message';
      //const start2 = new Date().getTime();
      //console.log(`Time took for avatar to take the input and speak: ${new Date().getTime() - start2}`);
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}
/**
 * sends learning prompt and retrieves the response
 * @param {*} userInput - textBox so that we can change placeholder
 * @param {string} prompt - string prmopt that will be passed on to the AI
 * @param {string} responseMode - either going to be KB, AI, KB+AI and this determines how the AI will generate the response
 * @param {string} KB - Knowledgebase
 * @param {string} url - Knowlege url
 * @param {bool} isInstructor - so that I can only send alert to instructor
 */
export async function handleLearning(
  userInput,
  prompt,
  responseMode,
  KB,
  url,
  isInstructor
) {
  toggleRecordingStatusVisibility(false);
  if (audioRecorder) {
    await audioRecorder.stopRecording();
    console.log('Recording stopped');
  }
  if (avatar && openaiAssistant) {
    try {
      userInput.placeholder = 'Sofia is generating response...';

      toggleinterruptableButton(false);
      let response;
      if (responseMode === 'AI') {
        response = await openaiAssistant.getResponse(prompt, avatar);
        if (isInstructor) alert(prompt);
      } else if (responseMode === 'KB') {
        response = await openaiAssistant.getResponse(
          `${prompt}\n generate your answer based on the information given below only \n ${KB} \n and the knowledge url by going through these\n ${url}`,
          avatar
        );
        if (isInstructor)
          alert(
            `${prompt}\n generate your answer based on the information given below only \n ${KB} \n and the knowledge url by going through these\n ${url}`
          );
      } else {
        response = await openaiAssistant.getResponse(
          `${prompt}\n generate your answer based on your own knowlegde, information given below\n ${KB} \n and the knowledge url by going through these\n ${url}`,
          avatar
        );
        if (isInstructor)
          alert(
            `${prompt}\n generate your answer based on your own knowlegde, information given below\n ${KB} \n and the knowledge url by going through these\n ${url}`
          );
      }
      console.log(response);
      toggleinterruptableButton(true);

      userInput.placeholder = 'Type your message';
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}
// Generating quiz
export async function handleQuiz(quizPrompt) {
  toggleRecordingStatusVisibility(false);
  if (audioRecorder) {
    await audioRecorder.stopRecording();
    console.log('Recording stopped');
  }
  if (avatar && openaiAssistant) {
    try {
      userInput.placeholder = 'Sofia is generating quiz';
      toggleinterruptableButton(false);
      const response = await openaiAssistant.getResponse(quizPrompt, avatar);
      //console.log(response);
      toggleinterruptableButton(true);
      userInput.placeholder = 'Type your message';
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}

export async function handleIOA(ioaPrompt) {
  toggleRecordingStatusVisibility(false);
  if (audioRecorder) {
    await audioRecorder.stopRecording();
    console.log('Recording stopped');
  }
  if (avatar && openaiAssistant) {
    try {
      userInput.placeholder = 'Sofia is generating IOA';
      toggleinterruptableButton(false);
      const response = await openaiAssistant.getResponse(ioaPrompt, avatar);
      toggleinterruptableButton(true);
      userInput.placeholder = 'Type your message';
    } catch (error) {
      console.error('Error getting response:', error);
    }
  }
}

export const handleChangeChatMode = async (mode) => {
  if (avatar) {
    if (mode === 'text_mode') {
      audioRecorder?.stopRecording();
      triggerUserSpeakingState(false);
    }
    if (mode === 'voice_mode') {
      await audioRecorder?.startRecording(openaiApiKey);
      triggerUserSpeakingState(true);
    }
  }
};

let setStateFunction: React.Dispatch<React.SetStateAction<boolean>> | null =
  null;

export const initializeEventSystem = (
  setValue: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setStateFunction = setValue; // Store the setState function
};

export const triggerUserSpeakingState = (value: boolean) => {
  if (setStateFunction) {
    setStateFunction(value); // Trigger the state update
  } else {
    console.error('State function is not initialized!');
  }
};

const toggleRecordingStatusVisibility = (visible) => {
  if (!muteButton || !speechIndicator) {
    console.warn('muteButton or speechIndicator not found');
  }

  if (muteButton) muteButton.style.display = visible ? 'block' : 'none';
  if (speechIndicator) speechIndicator.style.display = visible ? 'block' : 'none';
};

export const handleMuteStatus = (ismuted) => {
  isMuted = ismuted;
};

const toggleinterruptableButton = (visible) => {
  if (!interruptButton) {
    console.warn('interrupt button not found');
  }

  if (interruptButton) interruptButton.style.display = visible ? 'block' : 'none';
};
