export class AudioRecorder {
    private mediaRecorder: MediaRecorder | null = null;
    private audioChunks: Blob[] = [];
    private isRecording = false;
    private converted_text = "";
    private openaiApiKey;
    private timer;


    constructor(
        private onStatusChange: (status: string) => void,
        private onTranscriptionUpdate: (text: string) => void,
        private onTimeOut: (msg: string) => void
    ) {}

    async startRecording(openaiApiKey) {
        try {
            this.openaiApiKey = openaiApiKey;
            this.timer = 0;
            //console.log('Requesting microphone access...');
            const stream = await navigator.mediaDevices.getUserMedia({ audio: {
                noiseSuppression: true, // Reduce background noise
                echoCancellation: true, // Cancel echo if using speakers
                sampleRate: 16000,      // Ensure optimal sample rate for Whisper
            }, });
            //console.log('Microphone access granted');
            
            this.mediaRecorder = new MediaRecorder(stream);
            this.audioChunks = [];
            this.isRecording = true;

            this.mediaRecorder.ondataavailable = (event) => {

                if (event.data.size > 0 && this.isRecording) {
                    //console.log('Received audio chunk:', event.data.size, 'bytes');

                    if (this.timer === 20){
                        this.onTimeOut("Recording stopped because you were silent fot too long")
                    }
                    this.timer++;
                    //console.log(this.timer);
                    this.audioChunks.push(event.data);
                    const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
                    this.sendToWhisper(audioBlob, openaiApiKey);
                }
            };

            this.mediaRecorder.start(2000); // Collect data every second
            //console.log('Started recording');
            this.onStatusChange('Recording... Speak now');
        } catch (error) {
            console.error('Error starting recording:', error);
            this.onStatusChange('Error: ' + (error as Error).message);
        }
    }

    async stopRecording() {
        if (this.mediaRecorder && this.isRecording) {
            //console.log('Stopping recording...');
            this.mediaRecorder.stop();
            this.isRecording = false;
            this.onStatusChange('Processing audio...');
            
            // Stop all tracks in the stream
            const stream = this.mediaRecorder.stream;
            stream.getTracks().forEach(track => track.stop());

            this.mediaRecorder = null;

            this.audioChunks = [];
            this.converted_text = '';

            console.log("recording stopped successfully");
            // const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            // //console.log('Audio blob size:', audioBlob.size, 'bytes');
            // await this.sendToWhisper(audioBlob, this.openaiApiKey);
        }
    }

    async startRecoringAgain() {
        if (this.isRecording === false && this.openaiApiKey) {
            await this.startRecording(this.openaiApiKey);
            console.log("restarting recording");
        }
    }

    private async sendToWhisper(audioBlob: Blob, openaiApiKey) {
        try {
            //console.log('Sending audio to Whisper API...');
            const formData = new FormData();
            formData.append('file', audioBlob, 'audio.webm');
            formData.append('model', 'whisper-1');
            formData.append('language', 'en');
            formData.append('prompt', 'If you detect silence or background noise or if its unclear, return nothing');
            const backgroundNoise = ['silence', 'thank you','thank you.', 'thank you!', 'you', 'thank you.', 'thank you for watching', 'thank you for watching!', 'thanks for watching!', 'thanks for watching', 'thanks for watching.',
                'thank you for watching.', '.', '. .', "thank you for watching and don't forget to like and subscribe!", 
                "thank you for watching, please subscribe, like, comment and share"]

            const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${openaiApiKey}`,
                },
                body: formData
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
            }

            const data = await response.json();
            console.log('Received transcription:', data.text);
            this.onStatusChange('');

            if (this.converted_text.toLowerCase() === data.text.toLowerCase() && this.isRecording && data.text != null && data.text !== "" && !backgroundNoise.includes(data.text.toLowerCase())) {
                console.log('THIS! Sentence appears complete.');
                this.onTranscriptionUpdate(data.text);
    
                // Stop and restart recording safely
                await this.stopRecording();
            } 
            else {
                this.converted_text = data.text;
            }
            //console.log(data.text);
        } catch (error) {
            console.error('Error transcribing audio:', error);
            this.onStatusChange('Error: Failed to transcribe audio');
        }
    }
}