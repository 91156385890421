const generateTranscript = async (transcripts) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/curriculum/generate-transcripts`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transcripts: transcripts,
        }),
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const today = new Date();
        const date = today.toISOString().split('T')[0];
        const fileName = `Transcript_${date}.pdf`;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName || `transcript.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        console.log(response);
      });
  } catch (error) {
    console.error('Error generating transcripts: ', error);
  }
};

export default generateTranscript;
