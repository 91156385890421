/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import {
  Accordion,
  Form,
  Spinner,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  FormLabel,
} from 'react-bootstrap';
import ContentModal from './ContentModal';
import './accordion.css';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import TrainingGroundInst from '../components/TrainingGroundInst';
import { DarkModeContext } from '../context/DarkModeContext';

const MyCoursesListInst = ({ reload }) => {
  const [instructorEmails, setInstructorEmails] = useState(['']);
  const [courses, setCourses] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [rosterFile, setRosterFile] = useState(null);
  const [courseLoading, setCourseLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [uploadingRoster, setUploadingRoster] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isTrainingGroundActive, setIsTrainingGroundActive] = useState(false);
  const [showRosterForm, setShowRosterForm] = useState(false);
  const { user } = useContext(UserContext);
  const { isDarkMode } = useContext(DarkModeContext);
  const [showInstructorForm, setShowInstructorForm] = useState(false); // Instructor toggle

  const [, setUploadingInstructor] = useState(false);

  const navigate = useNavigate();

  const handleInstructorUpdate = async (courseId) => {
    setUploadingInstructor(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/add-instructor`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            course_id: courseId,
            instructor_email: instructorEmails,
          }),
        }
      );

      if (response.ok) {
        alert('Instructor(s) updated successfully!');
        setInstructorEmails(['']);
        setShowInstructorForm(false);
        fetchMyCourses();
        console.log({
          course_id: courseId,
          instructor_email: instructorEmails,
        });
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      alert('Error updating instructor(s)');
      console.error('Error:', error);
    } finally {
      setUploadingInstructor(false);
    }
  };

  const fetchMyCourses = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/instructor/${user.id}`
      );

      if (response.ok) {
        const data = await response.json();
        setCourses(data.courses);
      } else {
        console.error('Error fetching courses');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setCourseLoading(false);
    }
  };
  //------------------------------------------
  const handleInstructorEmailChange = (index, event) => {
    const newEmails = [...instructorEmails];
    newEmails[index] = event.target.value;
    setInstructorEmails(newEmails);
  };

  const handleRemoveEmailField = (index) => {
    if (instructorEmails.length > 1) {
      const newEmails = instructorEmails.filter((_, i) => i !== index);
      setInstructorEmails(newEmails);
    }
  };

  const handleAddEmailField = () => {
    if (instructorEmails.length < 5) {
      setInstructorEmails([...instructorEmails, '']);
    }
  };

  const deleteCourse = async (courseId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this course?'
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/${courseId}/delete`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 204) {
        alert('Course deleted successfully!');
        setCourses(courses.filter((course) => course.id !== courseId));
      } else {
        const data = await response.json();
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error deleting course:', error);
      alert('Error deleting course');
    }
  };

  useEffect(() => {
    fetchMyCourses();
  }, [user.id, reload]);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{title}</Tooltip>}
    >
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleRosterFileChange = (e) => {
    setRosterFile(e.target.files[0]);
  };

  const handleFileUpload = async (course_id) => {
    if (!csvFile) {
      alert('Please select a file first!');
      return;
    }

    setUploading(true);

    const tableOfContentsData = new FormData();
    tableOfContentsData.append('course_id', course_id);
    tableOfContentsData.append('file', csvFile);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/upload-content/${course_id}`,
        {
          method: 'POST',
          body: tableOfContentsData,
        }
      );
      const data = await response.json();

      if (response.ok) {
        alert(data.message);
        console.table(data.data);
        setCsvFile(null);
        document.querySelector('input[type="file"]').value = '';
      } else {
        alert(`Error: ${data.error}`);
        console.error('Error uploading file: ', data.error);
      }
    } catch (error) {
      alert(error);
      console.error('Error:', error);
    } finally {
      setUploading(false);
    }
  };

  const toggleInstructorForm = () => {
    setShowInstructorForm((prev) => !prev);
  };

  const handleRosterUpload = async (course_id) => {
    if (!rosterFile) {
      alert('Please select a file first!');
      return;
    }

    const confirmationMessage = `
    You are about to update the student roster for this course. Please note the following:
    - New students in the roster will be added to the course.
    - Existing students will retain their progress in the course.
    - Students not listed in the new roster will be removed from the course, and their progress will be deleted.
    Do you wish to proceed with these changes?
  `;

    const confirmUpdate = window.confirm(confirmationMessage);

    if (confirmUpdate) {
      setUploadingRoster(true);

      const rosterData = new FormData();
      rosterData.append('course_id', course_id);
      rosterData.append('file', rosterFile);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/update-roster/${course_id}`,
          {
            method: 'POST',
            body: rosterData,
          }
        );

        const data = await response.json();

        if (response.ok) {
          alert(data.message);
          setRosterFile(null);
          document.querySelector('input[type="file"]').value = '';

          fetchMyCourses();
        } else {
          alert(`Error: ${data.error}`);
          console.error('Error uploading student roster: ', data.error);
        }
      } catch (error) {
        alert('Error uploading roster');
        console.error('Error:', error);
      } finally {
        setUploadingRoster(false);
      }
    }
  };

  const handleManageClick = (course) => {
    setSelectedCourse(course);
    console.log(JSON.stringify(course));
    setShowContentModal(true);
  };

  const handleCloseModal = () => {
    setShowContentModal(false);
    setSelectedCourse(null);
  };

  const toggleRosterForm = () => {
    setShowRosterForm((prev) => !prev);
  };

  if (courseLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="my-courses-list" style={{ paddingBottom: '20px' }}>
      {isTrainingGroundActive ? (
        <div>
          <button
            className="back-button"
            onClick={() => setIsTrainingGroundActive(false)}
            style={{
              background: 'none',
              border: 'none',
              padding: '10px',
              borderRadius: '5px',
              cursor: 'pointer',
              marginBottom: '20px',
            }}
          >
            <i className="fa-solid fa-chevron-left"></i>
            Back
          </button>
          <TrainingGroundInst selectedCourse={selectedCourse} />
        </div>
      ) : (
        <>
          {courses.length === 0 ? (
            <p style={{ textAlign: 'left', padding: '20px 0' }}>
              No courses found. Please contact the administrator or create a new
              course.
            </p>
          ) : (
            <Accordion className="mb-3 mt-3 course-background">
              {courses.map((course) => (
                <Accordion.Item key={course.id} eventKey={course.id}>
                  <Accordion.Header>
                    <div style={{ flex: 1 }}>
                      <h5>
                        {course.course_name} {course.course_code}:{' '}
                        {course.description}
                      </h5>
                      <p>
                        {course.semester <= 4
                          ? `Quarter ${course.semester}`
                          : course.semester === 5
                          ? 'Semester 1'
                          : 'Semester 2'}{' '}
                        {course.year}
                      </p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="white">
                      <b>{course.instructor_count}</b>{' '}
                      {course.instructor_count > 1
                        ? 'instructors'
                        : 'instructor'}
                    </p>
                    <p className="white">
                      <b>{course.student_count}</b>{' '}
                      {course.student_count > 1 ? 'students' : 'student'}
                    </p>
                    <br />
                    <p>
                      <button
                        className="white"
                        type="button"
                        onClick={toggleInstructorForm}
                        style={{
                          background: 'none',
                          border: 'none',
                          color: '#2b2b2b',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          padding: '0',
                        }}
                      >
                        <i
                          className="fa-solid fa-chalkboard-user"
                          style={{
                            marginRight: '5px',
                          }}
                        ></i>
                        Update Instructor
                        <span style={{ marginLeft: '5px' }}>
                          <Link title="Add a new instructor here. Please ensure the email is registered.">
                            <i className="fa-solid fa-circle-info"></i>
                          </Link>{' '}
                        </span>
                      </button>
                    </p>

                    {showInstructorForm && (
                      <Form className="mb-3" style={{ margin: 0 }}>
                        <Row
                          style={{ display: 'flex' }}
                          className="align-items-center mb-2"
                        >
                          <Col xs="auto" className="d-flex mb-2">
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formCourseInstructors"
                            >
                              <FormLabel
                                column
                                sm="2"
                                style={{
                                  marginLeft: '3px',
                                  marginBottom: '8px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Instructor Emails{' '}
                              </FormLabel>
                              <Col sm="10">
                                {instructorEmails.map((email, index) => (
                                  <div
                                    key={index}
                                    className="email-input-row mb-2"
                                  >
                                    <Row className="align-items-center">
                                      <Col xs="10" sm="11" className="pe-1">
                                        <Form.Control
                                          type="email"
                                          // style={{ width: '200px' }}
                                          placeholder="example@gmail.com"
                                          value={email}
                                          onChange={(e) =>
                                            handleInstructorEmailChange(
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col xs="2" sm="1">
                                        {instructorEmails.length > 1 && (
                                          <button
                                            type="button"
                                            className="email-remove-button"
                                            onClick={() =>
                                              handleRemoveEmailField(index)
                                            }
                                          >
                                            <i className="fa-solid fa-minus"></i>
                                          </button>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                                {instructorEmails.length < 5 && (
                                  <Row className="email-add-row mt-2">
                                    <Col sm="1">
                                      <button
                                        type="button"
                                        className="email-add-button"
                                        onClick={handleAddEmailField}
                                      >
                                        <i className="fa-solid fa-plus"></i>
                                      </button>
                                    </Col>

                                    <Col sm="11"> </Col>
                                  </Row>
                                )}
                              </Col>
                              <button
                                type="button"
                                style={{
                                  width: '80px',
                                  marginLeft: '14px',
                                  marginTop: '10px',
                                }}
                                className="upload-button"
                                onClick={() =>
                                  handleInstructorUpdate(course.id)
                                }
                              >
                                Upload
                              </button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    <p>
                      <button
                        className="white"
                        type="button"
                        onClick={toggleRosterForm}
                        style={{
                          background: 'none',
                          border: 'none',
                          color: '#2b2b2b',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          padding: '0',
                        }}
                      >
                        <i
                          className="fa-regular fa-user"
                          style={{ marginRight: '5px' }}
                        ></i>
                        Update Student Roster
                        <span style={{ marginLeft: '5px' }}>
                          <Link title="Please upload a CSV file downloaded from Canvas. The file should include Student Name and Email.">
                            <i className="fa-solid fa-circle-info"></i>
                          </Link>
                        </span>
                      </button>
                    </p>

                    {showRosterForm && (
                      <Form className="mb-3" style={{ margin: 0 }}>
                        <Row className="align-items-center mb-2">
                          <Col xs="auto" className="d-flex mb-2">
                            <Form.Control
                              type="file"
                              accept=".csv"
                              onChange={(e) =>
                                handleRosterFileChange(e, course.id)
                              }
                            />
                          </Col>
                          <Col xs="auto" className="d-flex mb-2">
                            <button
                              type="button"
                              className="upload-button"
                              onClick={() => handleRosterUpload(course.id)}
                              disabled={uploadingRoster}
                            >
                              {uploadingRoster ? (
                                <div style={{ textAlign: 'center' }}>
                                  <Spinner
                                    animation="border"
                                    variant="secondary"
                                  />
                                </div>
                              ) : (
                                'Upload'
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    <Form className="mb-3" style={{ margin: 0 }}>
                      <p>
                        <b className="white">
                          Table of Contents{' '}
                          <Link title="Please upload a CSV file containing the table of contents. Ensure that your file is formatted with the following columns: Module, Topic and Sub-topic. ">
                            <i className="fa-solid fa-circle-info"></i>
                          </Link>{' '}
                        </b>
                      </p>
                      <Row className="align-items-center mb-2">
                        <Col xs="auto" className="d-flex mb-2">
                          <Form.Control
                            type="file"
                            accept=".csv"
                            style={{
                              marginBottom: '3px',
                              border: isDarkMode
                                ? '1px solid #ffffff29'
                                : '1px solid rgba(112, 110, 110, 0.28)',
                              backgroundColor: isDarkMode ? '#2b2b2b' : '#fff',
                              color: isDarkMode ? '#fff' : 'rgb(112, 110, 110)',
                            }}
                            onChange={(e) => handleFileChange(e, course.id)}
                          />
                        </Col>
                        <Col xs="auto" className="d-flex mb-2">
                          <button
                            type="button"
                            className="upload-button"
                            onClick={() => handleFileUpload(course.id)}
                            disabled={uploading}
                          >
                            {uploading ? (
                              <div style={{ textAlign: 'center' }}>
                                <Spinner
                                  animation="border"
                                  variant="secondary"
                                />
                              </div>
                            ) : (
                              'Upload'
                            )}
                          </button>
                        </Col>
                        <Col xs="auto" className="d-flex mb-2">
                          <button
                            style={{ marginRight: '6px' }}
                            type="button"
                            className="manage-button"
                            onClick={() => handleManageClick(course)}
                          >
                            <i className="fa-solid fa-gear"></i> Contents
                          </button>
                        </Col>
                        <Col xs="auto" className="d-flex mb-2">
                          <button
                            className="white2 training-button"
                            type="button"
                            style={{
                              background: 'none',
                              color: 'rgba(139, 0, 103, 0.7)',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              marginLeft: '-10px',
                              border: isDarkMode
                                ? '2px solid white'
                                : '2px solid rgba(139, 0, 103, 0.7)',
                              padding: '5px 10px',
                              borderRadius: '50px',

                              // paddingTop: '10px',
                            }}
                            onClick={() => {
                              setIsTrainingGroundActive(true);
                              setSelectedCourse(course);
                              navigate('/notification-direct', {
                                state: {
                                  selectedCourse: course,
                                },
                              });
                            }}
                          >
                            <i class="fa-solid fa-desktop"></i>{' '}
                            <span className="button-text">
                              {' '}
                              Training ground
                            </span>
                          </button>
                          <span style={{ marginLeft: '5px' }}>
                            <Link title="Training Ground is for Instructor can manually train AI, has options to load the course, also can edit each prompt, upload and edit KB documents and knowledge urls.">
                              <i className="fa-solid fa-circle-info"></i>
                            </Link>
                          </span>
                        </Col>
                      </Row>
                    </Form>

                    <br />
                    <button
                      type="button"
                      className="delete-button"
                      onClick={() => deleteCourse(course.id)}
                    >
                      Delete Course
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
          <ContentModal
            isOpen={showContentModal}
            closeModal={handleCloseModal}
            course={selectedCourse}
          />
        </>
      )}
    </div>
  );
};

export default MyCoursesListInst;
