/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from '../context/DarkModeContext';
import { UserContext } from '../context/UserContext';
import './cards.css';

import TrainingGroundInst from '../components/TrainingGroundInst';

const NotificationBell = () => {
  const [hasNotification, setHasNotification] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedCourse] = useState(null);
  const [isTrainingGroundActive, setIsTrainingGroundActive] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);
  const { user } = useContext(UserContext);
  const [dotPosition, setDotPosition] = useState({
    top: '27px',
    right: '170px',
  });

  const navigate = useNavigate();
  const modalRef = useRef(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const fetchAllNotifications = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/fetch-all-direct-feedbacks/${user.id}`
      );
      const data = await response.json();
      setNotifications(data.feedbacks);

      const hasNewFeedback = data.feedbacks.some(
        (feedback) => !feedback.viewed
      );

      const isBellClicked = localStorage.getItem(
        `hasNotificationViewed_${user.id}`
      );

      if (hasNewFeedback && isBellClicked !== 'true') {
        setHasNotification(true);
      } else {
        setHasNotification(false);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (user?.id) {
      fetchAllNotifications();
    }
  }, [user?.id]);

  useEffect(() => {
    if (notifications.length > 0) {
      const hasNewFeedback = notifications.some((feedback) => !feedback.viewed);
      const isBellClicked = localStorage.getItem(
        `hasNotificationViewed_${user.id}`
      );

      if (hasNewFeedback && isBellClicked !== 'true') {
        setHasNotification(true);
      }
    }
  }, [notifications]);

  const handleNotificationClick = (notification) => {
    console.log('Navigating with state:', notification);
    if (notification.course) {
      navigate('/notification-direct', {
        state: {
          selectedCourse: notification.course,
          selectedSubtopic: notification.topic_id,
        },
      });
    } else {
      console.error('Course data is missing in notification:', notification);
    }
  };

  const renderNotifications = () => {
    if (notifications.length > 0) {
      return notifications.map((notification, index) => (
        <div key={index} style={notificationItemStyle}>
          <div>
            <strong>{notification.from}</strong> New direct feedback for{' '}
            <strong>{notification.topic.split('(')[0].trim()}</strong> {' in '}
            <strong
              style={{
                color: isDarkMode ? 'rgb(132, 69, 115)' : 'rgba(139, 0, 103)',
              }}
            >
              {notification.course.course_name +
                notification.course.course_code}
            </strong>
          </div>
          <div style={buttonStyle}>
            <button
              className="go-to-page-button"
              onClick={() => {
                handleNotificationClick(notification);

                // setSelectedCourse(notification.course);
                // setIsTrainingGroundActive(true);
              }}
            >
              View Feedback
            </button>
          </div>
          <div style={timeAgoStyle}>
            <i className="fa-regular fa-clock"></i> Submitted:{' '}
            {formatDate(notification.date)}
          </div>
        </div>
      ));
    }

    return (
      <div
        style={{
          textAlign: 'center',
          color: isDarkMode ? '#ccc' : '#777',
          fontSize: '14px',
        }}
      >
        No new notifications
      </div>
    );
  };

  useEffect(() => {
    console.log('Current notifications:', notifications);
    console.log('Has new feedback:', hasNotification);
  }, [notifications, hasNotification]);

  useEffect(() => {
    const hasNewFeedback = notifications.some((feedback) => !feedback.viewed);
    setHasNotification(hasNewFeedback);
  }, [notifications]);

  const markNotificationsAsViewed = () => {
    const viewedNotifications =
      JSON.parse(localStorage.getItem('viewedNotifications')) || {};

    notifications.forEach(async (notification) => {
      viewedNotifications[notification.id] = true;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/users/toggle-direct-feedback`,
          {
            method: 'POST',
            body: JSON.stringify({
              topic_id: notification.topic_id,
              date: notification.date,
            }),
          }
        );
        console.log(response);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    });

    localStorage.setItem(
      'viewedNotifications',
      JSON.stringify(viewedNotifications)
    );

    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      viewed: true,
    }));
    setNotifications(updatedNotifications);
    setHasNotification(false);
  };

  useEffect(() => {
    checkHasNewFeedback(notifications);
  }, [notifications]);

  useEffect(() => {
    const updateDotPosition = () => {
      if (window.innerWidth <= 1200 && window.innerWidth > 1100) {
        setDotPosition({ top: '27px', right: '110px' });
      } else if (window.innerWidth <= 1100 && window.innerWidth > 576) {
        setDotPosition({ top: '27px', right: '110px' });
      } else if (window.innerWidth <= 576) {
        setDotPosition({ top: '27px', right: '80px' });
      } else {
        setDotPosition({ top: '27px', right: '170px' });
      }
    };

    updateDotPosition();
    window.addEventListener('resize', updateDotPosition);

    return () => window.removeEventListener('resize', updateDotPosition);
  }, []);

  const checkHasNewFeedback = (notificationList) => {
    const hasNewFeedback = notificationList.some(
      (feedback) => !feedback.viewed
    );
    setHasNotification(hasNewFeedback);
  };

  const handleClick = () => {
    setIsModalOpen(!isModalOpen);
    markNotificationsAsViewed();
  };

  useEffect(() => {
    const hasNewFeedback = notifications.some((feedback) => !feedback.viewed);
    setHasNotification(hasNewFeedback);
  }, [notifications]);

  const bellStyle = {
    position: 'relative',
    cursor: 'pointer',
    fontSize: '25px',
    color: isDarkMode ? '#888' : '#3b3b3b',
    marginLeft: '30px',
  };

  const dotStyle = {
    position: 'absolute',
    top: dotPosition.top,
    right: dotPosition.right,
    width: '10px',
    height: '10px',
    backgroundColor: 'red',
    borderRadius: '50%',
    border: isDarkMode ? '2px solid gray' : '2px solid white',
    display: hasNotification ? 'block' : 'none',
  };

  const modalStyle = {
    position: 'absolute',
    top: '7%',
    right: '9%',
    backgroundColor: isDarkMode ? '#2b2b2b' : 'white',
    border: `1px solid ${isDarkMode ? '#555' : '#ddd'}`,
    borderRadius: '10px',
    boxShadow: `0 4px 8px ${
      isDarkMode ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.1)'
    }`,
    width: '26vw',
    zIndex: '1000',

    color: isDarkMode ? '#fff' : '#000',
  };

  const headerStyle = {
    padding: '10px',
    fontWeight: 'bold',
    borderBottom: `1px solid ${isDarkMode ? '#444' : '#ddd'}`,
    fontSize: '16px',
    backgroundColor: isDarkMode ? '#333' : '#f9f9f9',
    color: isDarkMode ? '#fff' : '#000',
  };

  const bodyStyle = {
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '3px 10px',
    textAlign: 'center',
  };

  const notificationItemStyle = {
    padding: '10px',
    borderBottom: `1px solid ${isDarkMode ? '#555' : '#f0f0f0'}`,
    color: isDarkMode ? '#fff' : '#000',
  };

  const timeAgoStyle = {
    fontSize: '12px',
    color: isDarkMode ? '#ccc' : '#777',
    marginTop: '5px',
  };
  const trainingGroundContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const buttonStyle = {
    marginTop: '5px',
    marginBottom: '8px',
  };

  return (
    <div>
      {isTrainingGroundActive ? (
        <div style={trainingGroundContainerStyle}>
          <button
            className="back-button"
            onClick={() => setIsTrainingGroundActive(false)}
            style={buttonStyle}
          >
            <i className="fa-solid fa-chevron-left"></i> Back
          </button>
          <TrainingGroundInst selectedCourse={selectedCourse} />
        </div>
      ) : (
        <>
          <i
            className="fa-solid fa-bell"
            style={bellStyle}
            onClick={handleClick}
          ></i>
          <span style={dotStyle}></span>

          {isModalOpen && (
            <div ref={modalRef} style={modalStyle}>
              <div style={headerStyle}>Notifications</div>
              <div style={bodyStyle}>{renderNotifications()}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NotificationBell;
