import React from 'react';
import { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { DarkModeContext } from '../context/DarkModeContext';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FeedbackChart = ({ labels, chartData, title, label, text }) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: chartData,
        backgroundColor: isDarkMode
          ? 'rgba(227 ,122 ,200 , 0.3)'
          : 'rgba(139, 0, 103, 0.3)',
        borderColor: isDarkMode
          ? 'rgba(227 ,122 ,200 , 0.8)'
          : 'rgba(139, 0, 103, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const total = chartData.reduce((a, b) => a + b, 0);
            const percentage = ((context.raw / total) * 100).toFixed(2);
            return `${context.dataset.label}: ${context.raw} (${percentage}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: label,
        },
      },
      y: {
        title: {
          display: true,
          text: text,
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        suggestedMax:
          Math.max(...chartData) + Math.ceil(Math.max(...chartData) * 0.1),
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default FeedbackChart;
