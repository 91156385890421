/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Accordion,
  Form,
  ListGroup,
  Button,
} from 'react-bootstrap';

import { UserContext } from '../context/UserContext';
import '../pages/style.css';
//soul machine
import {
  onSendMessageTopic,
  onSendMessage,
  generateQuiz,
  generateIOA,
  initializeAI,
} from './SoulMachines';

//heygen
import {
  initializeAvatarSession,
  terminateAvatarSession,
  handleInitialSpeak,
  handleChangeChatMode,
  initializeEventSystem,
  interrupt,
  handleMuteStatus,
} from './heygen/main.ts';

import './accordion.css';
import QuizPassModal from './QuizPassModal';
import QuizFailModal from './QuizFailModal';
import {
  FaMicrophone,
  FaMicrophoneSlash,
} from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import logo from './Sofia-logo-white.png';
import { DarkModeContext } from '../context/DarkModeContext';

const TrainingGroundInst = ({
  selectedCourse,
  // selectedSubtopic,
  modules = [],
}) => {
  const { user } = useContext(UserContext);
  const [contentLoading, setContentLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [studentProgress, setStudentProgress] = useState({});
  const [passingScore, setPassingScore] = useState(null);
  const [quizResult, setQuizResult] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [isQuizResultProcessed, setIsQuizResultProcessed] = useState(false);
  const [isPassModalOpen, setIsPassModalOpen] = useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);
  const [IOA] = useState(false);
  const [, setDisplayContent] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);
  // Contents, url file
  const [contentsText, setContentsText] = useState('');
  const [urlText, setUrlText] = useState('');
  const [file, setFile] = useState('');

  const [dropdownValues, setDropdownValues] = useState({});
  const [KBValues, setKBValues] = useState({});
  const [urlValues, setUrlValues] = useState({});
  const [activeAccordionKeys, setActiveAccordionKeys] = useState([]);
  const sectionRefs = useRef({});
  const [editingSubtopicId, setEditingSubtopicId] = useState(null); // Tracks the currently editing subtopic
  const [fetchedPrompts, setFetchedPrompts] = useState({}); // Stores fetched prompts for each subtopic
  const [promptTextData, setPromptTextData] = useState({}); // Stores prompt text for each subtopic during editing
  const [feedbackData, setFeedbackData] = useState({}); // Store feedback for subtopics
  const [settingTriggered, setSettingTriggered] = useState({});
  const [visibleSetting, setVisibleSetting] = useState({});

  const layoutRef = useRef(null); // myProgress-layout ref

  const [visibleFeedback, setVisibleFeedback] = useState(() => {
    const initialState = {};
    if (modules && modules.length > 0) {
      modules.forEach((module) => {
        module.topics.forEach((topic) => {
          initialState[topic.topic_id] = false;
        });
      });
    }
    return initialState;
  });

  // const [showUploadFields, setShowUploadFields] = useState({});

  const [isSpeaking, setIsSpeaking] = useState(false);
  useEffect(() => {
    // Initialize the event system with the setState function
    initializeEventSystem(setIsSpeaking);
  }, []);

  const videoRef = useRef(null);
  const chatBoxRef = useRef(null);
  const userInputRef = useRef(null);
  const sendButtonRef = useRef(null);
  const reconnectButtonRef = useRef(null);
  const muteButtonRef = useRef(null);
  const interruptButtonRef = useRef(null);
  const speechIndicatorRef = useRef(null);

  const loadingImageRef = useRef(null);

  const location = useLocation();

  const handleOpenPassModal = () => setIsPassModalOpen(true);
  const handleOpenFailModal = () => setIsFailModalOpen(true);
  const handleClosePassModal = () => setIsPassModalOpen(false);
  const handleCloseFailModal = () => setIsFailModalOpen(false);
  const [, setModuleData] = useState({});
  //start message
  const startMessageLearning =
    "Hi there, what area of digital marketing can I help you with today? Please click on the topic you'd like to focus on.";
  const startMessageIOA =
    'Hi there, welcome the Direct Feedback from Students page. Please select the topic you want to cover in Direct Feedback from Students.';

  function handleQuizResult(score, moduleName, moduleId) {
    setIsQuizResultProcessed(false);
    setQuizResult({ score, moduleName, moduleId });
  }

  const handleTranscript = (transcript) => {
    setTranscript(transcript);
  };

  const toggleUploadFields = (moduleId) => {
    setShowUploadFields((prevState) => ({
      ...prevState,
      [moduleId]: !prevState[moduleId],
    }));
  };

  //--------------------------------------------------------------------------------
  const handleDirectNavigation = async (topicId) => {
    try {
      //console.log('!Navigating directly to Topic ID:', topicId);
      //console.log('!Student Progress:', studentProgress);

      const subtopics = Object.values(studentProgress).flatMap((module) =>
        module.topics.flatMap((topic) => topic.subtopics || [])
      );

      //console.log('!Extracted Subtopics:', subtopics);

      const matchedSubtopic = subtopics.find((sub) => sub.topic_id === topicId);

      if (!matchedSubtopic) {
        console.error(`!#Subtopic with topic_id ${topicId} not found.`);
        return;
      }

      //console.log('Matched --Subtopic:', matchedSubtopic);

      await Promise.all([
        handleToggleSetting2(matchedSubtopic),
        handleFeedbackToggle2(matchedSubtopic),
      ]);
      if (!visibleSetting[matchedSubtopic.topic_id]) {
        handleToggleSetting(matchedSubtopic);
      }
      // handleFeedbackToggle(matchedSubtopic.topic_id);
      setVisibleFeedback((prevState) => ({
        ...prevState,
        [matchedSubtopic.topic_id]: true,
      }));

      const moduleIndex = Object.keys(studentProgress).findIndex((moduleName) =>
        studentProgress[moduleName].topics.some((topic) =>
          topic.subtopics.includes(matchedSubtopic)
        )
      );

      const topicIndex = studentProgress[
        Object.keys(studentProgress)[moduleIndex]
      ].topics.findIndex((topic) =>
        topic.subtopics.some((sub) => sub.topic_id === matchedSubtopic.topic_id)
      );

      setActiveAccordionKeys((prevKeys) => [
        ...prevKeys,
        `module-${moduleIndex}`,
        `topic-${moduleIndex}-${topicIndex}`,
      ]);
    } catch (error) {
      console.error('!Error in handleDirectNavigation:', error);
    }
  };

  useEffect(() => {
    if (
      location.state?.selectedSubtopic &&
      Object.keys(studentProgress).length > 0
    ) {
      const topicId = location.state.selectedSubtopic;
      //console.log('#Received selectedSubtopic:', topicId);

      handleDirectNavigation(topicId);
    }
  }, [location.state?.selectedSubtopic, studentProgress]);

  const handleFeedbackToggle2 = async (subtopic) => {
    if (!subtopic || !subtopic.topic_id) {
      console.error('Invalid subtopic or topic_id:', subtopic);
      return;
    }

    //console.log('Toggling feedback for Topic ID:', subtopic.topic_id);

    setVisibleFeedback((prevState) => {
      const updatedState = {
        ...prevState,
        [subtopic.topic_id]: true,
      };
      //console.log('Updated visibleFeedback:', updatedState);
      return updatedState;
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/fetch-direct-feedback/${subtopic.topic_id}`
      );

      if (response.ok) {
        const data = await response.json();
        setFeedbackData((prev) => ({
          ...prev,
          [subtopic.topic_id]: data.feedbacks,
        }));
        //console.log('Fetched Feedback for Topic ID:', subtopic.topic_id);
      } else {
        console.error(
          `Failed to fetch feedback for Topic ID: ${subtopic.topic_id}`
        );
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
    }
  };

  const handleToggleSetting2 = async (subtopic) => {
    if (!subtopic || !subtopic.topic_id) {
      console.error('Invalid subtopic or topic_id:', subtopic);
      return;
    }

    //console.log('Toggling Setting for Topic ID:', subtopic.topic_id);

    setDropdownValues((prevValues) => {
      const previousValue = prevValues[subtopic.topic_id];
      return {
        ...prevValues,
        [subtopic.topic_id]: previousValue || 'AI',
      };
    });

    setVisibleFeedback((prev) => {
      const updatedState = {
        ...prev,
        [subtopic.topic_id]: !prev[subtopic.topic_id],
      };
      //console.log('Updated visibleFeedback:', updatedState);
      return updatedState;
    });
  };

  useEffect(() => {
    activeAccordionKeys.forEach((key) => {
      if (sectionRefs.current[key]) {
        sectionRefs.current[key].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    });
  }, [activeAccordionKeys]);

  useEffect(() => {
    //console.log('SectionRefs:', sectionRefs.current);
  }, []);

  // ...
  //...
  //--------------------------------------------------------------------------------
  // toggling setting button

  const handleToggleSetting = async (subtopic) => {
    //console.log('Toggling Setting for Topic ID:', subtopic.topic_id);
    setVisibleSetting((prev) => ({
      ...prev,
      [subtopic.topic_id]: !prev[subtopic.topic_id],
    }));

    const mode = await handleModeFetch(subtopic);
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [subtopic.topic_id]: mode || 'AI',
    }));

    const KBandURL = await handleKBFetch(subtopic.topic_id);
    let KB, url;
    if (KBandURL) {
      KB = KBandURL.text.join('\n');
      url = KBandURL.url.join('\n');
    }
    setKBValues((prevValues) => ({
      ...prevValues,
      [subtopic.topic_id]: KB,
    }));
    setUrlValues((prevValues) => ({
      ...prevValues,
      [subtopic.topic_id]: url,
    }));

    const prompt = await handleFetchPrompt(subtopic);

    setSettingTriggered((prev) => ({
      ...prev,
      [subtopic.topic_id]: true,
    }));
    //console.log('Setting is triggered for:', subtopic);

    return {
      prompt,
      mode: mode || 'AI',
      KB,
      url,
    };
  };

  //auto scroll
  useEffect(() => {
    const handleScrollToLayout = () => {
      if (layoutRef.current) {
        layoutRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    activeAccordionKeys.forEach((key) => {
      if (sectionRefs.current[key]) {
        sectionRefs.current[key].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    });

    handleScrollToLayout();
  }, [activeAccordionKeys]);

  useEffect(() => {
    if (!location.state) {
      //console.log('Location state is not ready yet.');
      return;
    }

    if (location.state?.selectedSubtopic) {
      const topicId = location.state.selectedSubtopic;
      //console.log('#Received selectedSubtopic:', topicId);
      handleDirectNavigation(topicId);
    }
  }, [location.state]);

  // Prompt functions
  const handlePromptSubmit = async (subtopicId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/update-prompt/${subtopicId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            prompt: promptTextData[subtopicId], // Use the specific subtopic's prompt text
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert(`Prompt submitted successfully: ${result.message}`);

        // Update cached prompt data
        setFetchedPrompts((prev) => ({
          ...prev,
          [subtopicId]: promptTextData[subtopicId],
        }));

        setEditingSubtopicId(null); // Exit editing mode
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      console.error('Error submitting prompt:', error);
      alert('An unexpected error occurred while submitting the prompt.');
    }
  };

  const handleFetchPrompt = async (subtopic) => {
    try {
      const defaultPrompt = `I want to learn about ${subtopic.topic_name} in digital marketing.`;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/fetch-prompt/${subtopic.topic_id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        //console.log(result.prompts);

        let prompt;

        if (result.prompts === '') {
          prompt = defaultPrompt;
        } else {
          prompt = result.prompts;
        }

        setFetchedPrompts((prev) => ({
          ...prev,
          [subtopic.topic_id]: prompt, // Cache fetched prompt for this subtopic
        }));

        setPromptTextData((prev) => ({
          ...prev,
          [subtopic.topic_id]: prompt, // Initialize prompt text for editing
        }));
        return prompt;
      } else {
        const error = await response.json();
        console.error(`Error fetching prompt: ${error.error}`);
      }
    } catch (error) {
      console.error('Error fetching prompt:', error);
    }
  };

  const handleEditPromptToggle = async (subtopic) => {
    if (editingSubtopicId === subtopic.topic_id) {
      setEditingSubtopicId(null); // Close editor
    } else {
      setEditingSubtopicId(subtopic.topic_id); // Open editor
    }
  };

  const handleDeletePrompt = async (subtopic) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/delete-prompt/${subtopic.topic_id}`, // Replace with your endpoint
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        //console.log('Prompt deleted successfully:', result);
        // Perform any additional state updates here if needed
      } else {
        const error = await response.json();
        console.error('Error deleting prompt:', error);
        alert(`Error deleting prompt: ${error.message}`);
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred while deleting the prompt.');
    }
  };
  // End Prompt functions

  // Mode switching functions
  const handleModeUpdate = async (subtopicId, mode) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/update-study-mode/${subtopicId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mode }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        //console.log(result.mode);
        alert(`Mode updated successfully: ${result.message}`);
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      console.error('Error submitting prompt:', error);
      alert('An unexpected error occurred while submitting the prompt.');
    }
  };

  const handleModeFetch = async (subtopic) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/fetch-study-mode/${subtopic.topic_id}`
      );

      if (response.ok) {
        const result = await response.json();
        //console.log('mode raw: ', result);

        return result.mode;
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      console.error('Error submitting prompt:', error);
      alert('An unexpected error occurred while submitting the prompt.');
    }
  };
  //End of Mode Switching

  // KB managing functions
  const handleKBUpload = async (subtopicId, text, url, file) => {
    if (!text && !url && !file) {
      alert('Please provide either text content or a Knowledge URL!');
      return;
    }
    if (file) {
      //console.log("File selected:", file);
      const validExtensions = ['pdf', 'ppt', 'pptx'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        alert('Invalid file type. Only PDF and PPTX files are allowed.');
        return;
      }

      // Proceed with upload
      //console.log("File is valid. Proceeding...");
    }

    try {
      const formData = new FormData();
      formData.append('knowledge_text', text); // Append the text
      formData.append('knowledge_url', url); // Append the URL
      if (file) {
        formData.append('file', file); // Append the file if it exists
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/upload-knowledge-base/${subtopicId}`,
        {
          method: 'POST',
          body: formData, // Send the FormData object
        }
      );

      if (response.ok) {
        const { new_kb } = await response.json();

        setDropdownValues((prevValues) => ({
          ...prevValues,
          [subtopicId]: 'KB',
        }));

        handleModeUpdate(subtopicId, 'KB');

        alert(
          "Content uploaded successfully\nThe dropdown has been updated to 'Knowledge-Based'. If you want to change to 'Split: KB + AI', please change it in the dropdown."
        );

        setModuleData((prevData) => ({
          ...prevData,
          [subtopicId]: { text: '', url: '' },
        }));

        setKBValues((prev) => ({
          ...prev,
          [subtopicId]: prev[subtopicId]
            ? prev[subtopicId] + '\n' + new_kb.knowledge_text
            : new_kb.knowledge_text,
        }));

        setUrlValues((prev) => ({
          ...prev,
          [subtopicId]: prev[subtopicId]
            ? prev[subtopicId] + '\n' + new_kb.knowledge_url
            : new_kb.knowledge_url,
        }));

        setFile('');
        setUrlText('');
        setContentsText('');
      } else {
        alert(`Failed to upload content for module: ${subtopicId}`);
      }
    } catch (error) {
      console.error('Error uploading content:', error);
      alert(`An error occurred during the upload for module: ${subtopicId}`);
    }
  };

  const handleKBFetch = async (subtopicId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/fetch-knowledge-base/${subtopicId}`
      );

      if (response.ok) {
        const result = await response.json();
        return result.knowledge_texts;
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      console.error('Error fetching prompt:', error);
      alert('An unexpected error occurred while fetching the prompt.');
    }
  };

  const handleKBDelete = async (subtopic) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/delete-knowledge-base/${subtopic.topic_id}`, // Replace with your endpoint
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        // const result = await response.json();
        //console.log('KB deleted successfully:', result);
        // Perform any additional state updates here if needed
      } else {
        const error = await response.json();
        console.error('Error deleting KB:', error);
        alert(`Error deleting KB: ${error.message}`);
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred while deleting the KB.');
    }
  };

  const [showUploadFields, setShowUploadFields] = useState(() => {
    const initialState = {};
    modules.forEach((module) => {
      initialState[module.id] = false;
    });
    return initialState;
  });

  const [, setShowFeedbackFields] = useState(() => {
    const initialState = {};
    modules.forEach((module) => {
      initialState[module.id] = false;
    });
    return initialState;
  });

  const handleFeedbackToggle = async (topicId) => {
    if (!topicId) {
      console.error('Invalid topicId:', topicId);
      return; // Early exit if topicId is undefined
    }

    //console.log('Toggling feedback for topic ID:', topicId);

    setVisibleFeedback((prevState) => ({
      ...prevState,
      [topicId]: !prevState[topicId],
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/fetch-direct-feedback/${topicId}`
      );

      if (response.ok) {
        const data = await response.json();
        setFeedbackData((prev) => ({
          ...prev,
          [topicId]: data.feedbacks,
        }));
        //console.log('Fetched feedback for topic ID:', topicId);
      } else {
        console.error(`Failed to fetch feedback: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
    }
  };

  const fetchFeedback = async (subtopicId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/fetch-direct-feedback/${subtopicId}`
      );
      if (response.ok) {
        const data = await response.json();
        //console.log('Fetched Feedback:', data);
        setFeedbackData((prev) => ({
          ...prev,
          [subtopicId]: data.feedback || [],
        }));
      } else {
        console.error('Failed to fetch feedback. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
    }
  };

  const handleDeleteFeedback = async (topicId, upi, feedbackText) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/delete-direct-feedback/${topicId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            upi: upi,
            feedbackText: feedbackText,
          }),
        }
      );

      if (response.ok) {
        alert('Feedback deleted successfully');
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/users/fetch-direct-feedback/${topicId}`
          );

          if (response.ok) {
            const data = await response.json();
            setFeedbackData((prev) => ({
              ...prev,
              [topicId]: data.feedbacks,
            }));
            feedbackData[topicId] = data.feedbacks;
            //console.log(feedbackData[topicId]);
          } else {
            console.error(`Failed to fetch feedback: ${response.status}`);
          }
        } catch (error) {
          console.error('Error fetching feedback:', error);
        }
        // Remove the deleted feedback from the state
        // setFeedbackData((prevData) => ({
        //   ...prevData,
        //   [topicId]: prevData[topicId].filter(
        //     (feedback) => feedback.id !== feedbackId
        //   ),
        // }));
      } else {
        const errorData = await response.json();
        console.error('Failed to delete feedback:', errorData.error);
        alert('Failed to delete feedback');
      }
    } catch (error) {
      console.error('Error deleting feedback:', error);
      alert('An error occurred while deleting feedback');
    }
  };

  useEffect(() => {
    if (quizResult && passingScore !== null && !isQuizResultProcessed) {
      const { score, moduleName } = quizResult;

      if (score >= passingScore) {
        handleOpenPassModal();

        setStudentProgress((prevState) => {
          const updatedProgress = { ...prevState };
          const moduleData = updatedProgress[moduleName];

          if (moduleData) {
            updatedProgress[moduleName].quiz_passed = true;

            const allTopicsComplete = moduleData.topics.every(
              (topic) => topic.is_complete
            );

            if (allTopicsComplete && !moduleData.is_complete) {
              updatedProgress[moduleName].is_complete = true;
            }
          }
          return updatedProgress;
        });
      } else {
        handleOpenFailModal();
      }
      setIsQuizResultProcessed(true);
    }
  }, [quizResult, passingScore, isQuizResultProcessed]);

  useEffect(() => {
    fetchFeedback();
  }, []);

  useEffect(() => {
    if (modules.length > 0) {
      const newData = {};
      modules.forEach((module) => {
        module.topics.forEach((topic) => {
          topic.subtopics.forEach((subtopic) => {
            newData[subtopic.topic_id] = { text: '', url: '' };
          });
        });
      });
      setModuleData(newData);
    }
  }, [modules]);

  useEffect(() => {
    if (!modules || modules.length === 0) return;

    const initialDropdownValues = modules.reduce((acc, module) => {
      module.topics.forEach((topic) => {
        topic.subtopics.forEach((subtopic) => {
          acc[subtopic.topic_name] = ''; // Initialize with an empty string
        });
      });
      return acc;
    }, {});

    setDropdownValues(initialDropdownValues);
  }, [modules]);

  const handleDropdownChange = (subtopicId, value) => {
    setDropdownValues((prev) => ({
      ...prev,
      [subtopicId]: value,
    }));
  };
  // const fetchSubtopicFeedback = async (subtopicId) => {
  //   setLoadingFeedback(true);
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}/feedback/${subtopicId}`
  //     );
  //     if (response.ok) {
  //       const data = await response.json();
  //       setSelectedFeedback(data.feedback);
  //       //console.log('Fetching feedback for subtopicId:', subtopicId);
  //     } else {
  //       console.error('Failed to fetch feedback for subtopic:', subtopicId);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching feedback for subtopic:', error);
  //   } finally {
  //     setLoadingFeedback(false);
  //   }
  // };

  const handleResetDropdown = (subtopic) => {
    //console.log('Reset button triggered');

    handleModeUpdate(subtopic.topic_id, 'AI');
    setDropdownValues((prev) => ({
      ...prev,
      [subtopic.topic_id]: 'AI',
    }));

    handleDeletePrompt(subtopic);
    setFetchedPrompts((prev) => ({
      ...prev,
      [subtopic.topic_id]: `I want to learn about ${subtopic.topic_name} in digital marketing.`,
    }));

    handleKBDelete(subtopic);
    setKBValues((prev) => ({
      ...prev,
      [subtopic.topic_id]: '',
    }));
    setUrlValues((prev) => ({
      ...prev,
      [subtopic.topic_id]: '',
    }));

    //console.log('all things deleted and reset');
  };

  useEffect(() => {
    const fetchStudentProgress = async () => {
      if (selectedCourse) {
        // setContentLoading(true);
        try {
          const progressResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-progress/${selectedCourse.id}/${user.id}`
          );

          if (progressResponse.ok) {
            const progressData = await progressResponse.json();
            setStudentProgress(progressData.data);
            //console.log('fetchStudentProgress', progressData);
          } else {
            console.error('Error fetching student progress');
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          // setContentLoading(false);
        }
      } else {
        setStudentProgress({});
      }
    };

    fetchStudentProgress();
    handleConnectClick();

    const handleBeforeUnload = (event) => {
      // //soul machine
      //updateSMData(selectedCourse.id, user.id);

      //heygen
      // updateAIData(selectedCourse.id, user.id);
      const message =
        'You have unsaved changes. Are you sure you want to leave?';
      event.preventDefault();
      event.returnValue = message;
      terminateAvatarSession();
      return message;
    };

    const handleResize = () => {
      const elements = document.getElementsByClassName('course-content-list');
      if (window.innerWidth < 900) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = 'none';
          setDisplayContent(false);
        }
      } else {
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = 'block';
        }
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    window.addEventListener('beforeunload', handleBeforeUnload);

    window.hasUnsavedChanges = true;

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('resize', handleResize);
      //console.log('update');
    };
  }, [selectedCourse, user.id]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('sessionId');
      terminateAvatarSession();
    };
  }, [location]);

  const handleConnectClick = async (IOAimmediate) => {
    setContentLoading(true);
    //console.log('iteration');
    await new Promise((resolve) => setTimeout(resolve, 10));
    if (!isMuted) {
      handleMuteClick();
    }
    //console.log(videoRef.current);

    if (videoRef.current && chatBoxRef.current) {
      //HeyGen Connection
      const userInputHeyGen = userInputRef.current;
      const sendButtonHeyGen = sendButtonRef.current;
      const reconnectButton = reconnectButtonRef.current;
      const muteButton = muteButtonRef.current;
      const interruptButton = interruptButtonRef.current;
      const speechIndicator = speechIndicatorRef.current;
      const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
      const HeyGenApiKey = process.env.REACT_APP_HEYGEN_API_KEY;

      if (speechIndicator) speechIndicator.style.display = 'none';

      //console.log(openaiApiKey);
      //console.log(HeyGenApiKey);

      //console.log('IOA mode', IOAimmediate);

      initializeAI(
        videoRef.current,
        chatBoxRef.current,
        selectedCourse.id,
        user.id
      );
      const initialized = await initializeAvatarSession(
        videoRef.current,
        sendButtonHeyGen,
        userInputHeyGen,
        reconnectButton,
        muteButton,
        interruptButton,
        speechIndicator,
        openaiApiKey,
        HeyGenApiKey,
        IOAimmediate
      );

      if (initialized) {
        setShowButtons(false);
        setContentLoading(false);
        const loadingimg = loadingImageRef.current;
        if (loadingimg != null) {
          loadingimg.style.display = 'none';
        }
        handleInitialSpeak(
          IOAimmediate ? startMessageIOA : startMessageLearning
        );
      }
    } else {
      handleConnectClick();
    }
  };

  const handleSendMessageClick = async () => {
    const userText = userInputRef.current;
    if (userText) {
      await onSendMessage(userText);
    }
  };

  const handleSendMessageEnter = async (event) => {
    const userText = userInputRef.current;
    if (userText && event.key === 'Enter') {
      await onSendMessage(userText);
      event.preventDefault();
    }
  };

  const handleAccordionClose = (key) => {
    if (activeAccordionKeys.includes(key)) {
      //console.log(`Closing accordion with key: ${key}`);

      setActiveAccordionKeys((prevKeys) => {
        const updatedKeys = prevKeys.filter((k) => k !== key);
        //console.log('Updated activeAccordionKeys:', updatedKeys);
        return updatedKeys;
      });

      // 세팅 버튼 상태 초기화
      setVisibleSetting(() => {
        const newState = {};
        Object.keys(visibleSetting).forEach((topicId) => {
          newState[topicId] = false;
        });
        return newState;
      });

      //console.log(`Accordion closed and settings reset for key: ${key}`);
    }
  };

  useEffect(() => {
    //console.log('Current visibleFeedback state:', visibleFeedback);
  }, [visibleFeedback]);

  useEffect(() => {
    //console.log('Current activeAccordionKeys state:', activeAccordionKeys);
  }, [activeAccordionKeys]);

  const handleSendMessageLearning = async (e, subtopic, courseid, userid) => {
    // setPromptTextData((prev) => ({
    //   ...prev,
    //   [subtopic.topic_id]: e.target.value, // Update only the specific subtopic's text
    // }));
    const userText = userInputRef.current;
    const textInput =
      fetchedPrompts[subtopic.topic_id] ||
      `I want to learn about ${subtopic.topic_name} in digital marketing.`;
    //console.log('This text will be sent to the AI:', textInput);

    const responseMode = dropdownValues[subtopic.topic_id];
    //console.log('response mode', responseMode);
    const KB = KBValues[subtopic.topic_id];
    const url = urlValues[subtopic.topic_id];

    onSendMessageTopic(
      textInput,
      '',
      courseid,
      userid,
      userText,
      responseMode,
      KB,
      url,
      true
    );
  };

  const handleMuteClick = async () => {
    try {
      const bool = !isMuted;
      setIsMuted(bool);
      handleMuteStatus(bool);
      handleChangeChatMode(bool ? 'text_mode' : 'voice_mode');
      //console.log('mute status: ', !isMuted);
    } catch (error) {
      console.error('Error toggling mute:', error);
    }
  };

  // const handleShowContent = () => {
  //   setDisplayContent(!showContent);
  //   const elements = document.getElementsByClassName('course-content-list');
  //   for (let i = 0; i < elements.length; i++) {
  //     elements[i].style.display = showContent ? 'none' : 'block';
  //   }
  // };

  // const handleIOAswitch = async () => {
  //   try {
  //     const IOAstatus = await toggleIOA(IOA);
  //     //console.log('IOA has changed to: ', IOAstatus);
  //     setIsIOA(IOAstatus);
  //     await terminateAvatarSession();
  //     handleConnectClick(IOAstatus);
  //   } catch (error) {
  //     console.error('Error toggling IOA:', error);
  //   }
  // };

  // const closeAllSubtopics = () => {
  //   setVisibleFeedback(() => {
  //     const newState = {};
  //     Object.keys(visibleFeedback).forEach((key) => {
  //       newState[key] = false;
  //     });
  //     return newState;
  //   });
  // };
  useEffect(() => {
    if (modules && modules.length > 0) {
      const initialState = modules.reduce((acc, module) => {
        acc[module.id] = false;
        return acc;
      }, {});
      setShowFeedbackFields(initialState);
    }
  }, [modules]);

  useEffect(() => {
    if (modules && modules.length > 0) {
      const initialState = {};
      modules.forEach((module) => {
        module.topics.forEach((topic) => {
          topic.subtopics.forEach((subtopic) => {
            initialState[subtopic.topic_id] = false; // 초기값 false 설정
          });
        });
      });
      setVisibleFeedback(initialState);
      //console.log('Initial visibleFeedback:', initialState);
    }
  }, [modules]);

  useEffect(() => {
    if (modules && modules.length > 0) {
      const initialState = modules.reduce((acc, module) => {
        acc[module.id] = false;
        return acc;
      }, {});
      setShowFeedbackFields(initialState);
    }
  }, [modules]);

  useEffect(() => {
    const handleResize = () => {
      const openAccordionKey =
        activeAccordionKeys[activeAccordionKeys.length - 1];
      if (openAccordionKey && sectionRefs.current[openAccordionKey]) {
        const selectedSection = sectionRefs.current[openAccordionKey];
        const sectionRect = selectedSection.getBoundingClientRect();
        const videoBox = document.querySelector('.video-container');
        const chatContainer = document.querySelector(
          '.myProgress-chat-container'
        );

        if (videoBox && chatContainer) {
          const topPosition = `${window.scrollY + sectionRect.top}px`;
          videoBox.style.top = topPosition;
          chatContainer.style.top = `calc(${topPosition} + 220px)`;
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeAccordionKeys]);

  useEffect(() => {
    //console.log('Updated IOA is: ', IOA);
  }, [IOA]);

  const indexStyle = { color: '#777777' };

  // ---------------------------------------------------

  // const handleAccordionSelect = (key) => {
  //   setActiveAccordionKeys((prevKeys) =>
  //     prevKeys.includes(key)
  //       ? prevKeys.filter((k) => k !== key)
  //       : [...prevKeys, key]
  //   );

  //
  //   syncMyProgressLayoutWithAccordion(key);
  // };

  // ---------------------------------------------------

  return (
    <div className="course-content" style={{ display: 'flex' }}>
      <div className="course-content-list2">
        <Accordion
          activeKey={activeAccordionKeys}
          onSelect={(key) => {
            setActiveAccordionKeys((prevKeys) =>
              prevKeys.includes(key)
                ? prevKeys.filter((k) => k !== key)
                : [...prevKeys, key]
            );
            handleAccordionClose(key);
          }}
          className="mt-2 pb-3"
        >
          <Accordion.Item className="pt-3 ps-3 pb-1">
            <h5>
              {selectedCourse
                ? `${selectedCourse.course_name} ${selectedCourse.course_code}: ${selectedCourse.description}`
                : 'No course selected'}
            </h5>
          </Accordion.Item>
          {Object.keys(studentProgress).length === 0 ? (
            <Accordion.Item className="pt-3 ps-3 pb-1">
              <p>
                No content available for {selectedCourse.course_name}{' '}
                {selectedCourse.course_code}: {selectedCourse.description}
              </p>
            </Accordion.Item>
          ) : !showButtons ? (
            // Module

            Object.entries(studentProgress).map(
              ([module, moduleData], moduleIndex) => (
                <Accordion.Item
                  eventKey={`module-${moduleIndex}`}
                  key={moduleIndex}
                  ref={(el) => {
                    if (el) {
                      sectionRefs.current[`module-${moduleIndex}`] = el;
                      console.log(
                        'sectionRefs.current11:',
                        sectionRefs.current
                      );
                    }
                  }}
                >
                  <Accordion.Header>
                    <div style={{}}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {!IOA ? (
                          <div style={{}}>
                            {/* {studentProgress[module]?.is_complete
                              ? completedIcon
                              : notCompletedIcon} */}
                          </div>
                        ) : (
                          ''
                        )}
                        <span style={indexStyle}>{moduleIndex + 1}</span>
                        <div style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                          {module}
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Accordion
                      activeKey={activeAccordionKeys}
                      onSelect={(key) => {
                        setActiveAccordionKeys((prevKeys) =>
                          prevKeys.includes(key)
                            ? prevKeys.filter((k) => k !== key)
                            : [...prevKeys, key]
                        );
                        handleAccordionClose(key);
                      }}
                      className="mt-2 pb-3"
                    >
                      {/* Topic */}
                      {moduleData.topics.map((topic, topicIndex) => {
                        const subtopics = topic.subtopics;

                        // If topic has at least 1 subtopic, display as accordion
                        return subtopics.length > 0 ? (
                          <Accordion.Item
                            eventKey={`topic-${moduleIndex}-${topicIndex}`}
                            key={topicIndex}
                          >
                            <Accordion.Header>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {!IOA ? <div style={{}}></div> : ''}
                                <span style={indexStyle}>
                                  {moduleIndex + 1}.{topicIndex + 1}
                                </span>
                                <div style={{ marginLeft: '1em' }}>
                                  {topic.topic_name}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <ListGroup as="ol">
                                {/* Subtopics displayed as ListGroup.Item (not expandable) */}
                                {subtopics.map((subtopic, subtopicIndex) => (
                                  <ListGroup.Item as="li" key={subtopicIndex}>
                                    <div
                                      style={{
                                        alignItems: 'center',
                                        position: 'relative',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {!IOA ? (
                                          <div style={{}}>
                                            {/* {subtopic.is_complete
                                            ? completedIcon
                                            : notCompletedIcon} */}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        <span style={indexStyle}>
                                          {moduleIndex + 1}.{topicIndex + 1}.
                                          {subtopicIndex + 1}
                                        </span>
                                        <div style={{ marginLeft: '1em' }}>
                                          <span
                                            onClick={async (e) => {
                                              if (IOA) {
                                                generateIOA(
                                                  user.id,
                                                  subtopic.topic_name
                                                );
                                                //console.log('IOA');
                                              } else {
                                                if (
                                                  settingTriggered[
                                                    subtopic.topic_id
                                                  ] !== true
                                                ) {
                                                  const {
                                                    prompt,
                                                    mode,
                                                    KB,
                                                    url,
                                                  } = await handleToggleSetting(
                                                    subtopic
                                                  );
                                                  const userText =
                                                    userInputRef.current;
                                                  onSendMessageTopic(
                                                    prompt,
                                                    `I want to learn about ${subtopic.topic_name} in digital marketing.`,
                                                    selectedCourse.id,
                                                    user.id,
                                                    userText,
                                                    mode,
                                                    KB,
                                                    url,
                                                    true
                                                  );
                                                } else {
                                                  handleSendMessageLearning(
                                                    e,
                                                    subtopic,
                                                    selectedCourse.id,
                                                    user.id
                                                  );
                                                }
                                                //console.log('Learning');
                                              }
                                            }}
                                            style={{
                                              cursor: 'pointer',
                                              color: 'inherit',
                                            }}
                                          >
                                            {subtopic.topic_name}
                                          </span>
                                        </div>
                                      </div>

                                      <button
                                        className=" setting-btn-inst"
                                        onClick={() =>
                                          handleToggleSetting({
                                            topic_id: subtopic.topic_id,
                                          })
                                        }
                                      >
                                        <i
                                          class="fa-solid fa-gear"
                                          style={{ paddingRight: '10px' }}
                                        ></i>
                                        <span> Settings </span>
                                      </button>
                                    </div>
                                    {visibleSetting[subtopic.topic_id] && (
                                      <div
                                        style={{
                                          marginTop: '10px',
                                          padding: '10px',
                                          border: '1px solid #ddd',
                                          borderRadius: '8px',
                                        }}
                                      >
                                        {/* Dropdown and Reset */}
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: '10px',
                                          }}
                                        >
                                          <Form.Select
                                            className="kb-ai-dropdown"
                                            style={{
                                              width: '100%',
                                              marginRight: '10px',
                                            }}
                                            value={
                                              dropdownValues[subtopic.topic_id]
                                            }
                                            onChange={async (e) => {
                                              const newValue = e.target.value;
                                              setDropdownValues(
                                                (prevValues) => ({
                                                  ...prevValues,
                                                  [subtopic.topic_id]: newValue,
                                                })
                                              );

                                              await handleModeUpdate(
                                                subtopic.topic_id,
                                                e.target.value
                                              );
                                              handleDropdownChange(
                                                subtopic.topic_id,
                                                e.target.value
                                              );
                                            }}
                                            defaultValue=""
                                          >
                                            <option value="" disabled>
                                              Select
                                            </option>
                                            <option value="KB">
                                              Knowledge-Based
                                            </option>
                                            <option value="AI">AI-Based</option>
                                            <option value="KB+AI">
                                              Split: Knowledge & AI
                                            </option>
                                          </Form.Select>

                                          <Button
                                            variant="danger"
                                            style={{
                                              fontSize: '15px',
                                              padding: '5px 9px',
                                              borderRadius: '5px',
                                              backgroundColor: '#777',
                                              border: '1px solid #777',
                                            }}
                                            onClick={() =>
                                              handleResetDropdown(subtopic)
                                            }
                                          >
                                            <i class="fa-solid fa-arrow-rotate-right"></i>
                                          </Button>
                                        </div>
                                        {/* Prompt Edit */}
                                        <div key={subtopic.topic_id}>
                                          <button
                                            onClick={() =>
                                              handleEditPromptToggle(subtopic)
                                            }
                                            className="prompt-edit-btn"
                                            style={{
                                              marginTop: '10px',
                                              marginLeft: '8px',
                                              marginBottom: '10px',
                                              textDecoration: 'underline',
                                              border: 'none',
                                              background: 'transparent',
                                              cursor: 'pointer',
                                              fontWeight: 'bold',
                                              color: isDarkMode
                                                ? '#fff'
                                                : '#000',
                                            }}
                                          >
                                            <i
                                              className={`fa-solid ${
                                                editingSubtopicId ===
                                                subtopic.topic_id
                                                  ? 'fa-times'
                                                  : 'fa-pencil'
                                              }`}
                                              style={{ paddingRight: '10px' }}
                                            ></i>
                                            {editingSubtopicId ===
                                            subtopic.topic_id
                                              ? 'Close Prompt Edit'
                                              : 'Edit Prompt'}
                                          </button>

                                          {editingSubtopicId ===
                                            subtopic.topic_id && (
                                            <div style={{ marginTop: '10px' }}>
                                              <textarea
                                                type="text"
                                                placeholder={
                                                  fetchedPrompts[
                                                    subtopic.topic_id
                                                  ] ||
                                                  `I want to learn about ${subtopic.topic_name} in digital marketing.`
                                                }
                                                onChange={(e) =>
                                                  setPromptTextData((prev) => ({
                                                    ...prev,
                                                    [subtopic.topic_id]:
                                                      e.target.value, // Update only the specific subtopic's text
                                                  }))
                                                }
                                                style={{
                                                  width: '95%',
                                                  heigh: '400px',
                                                  padding: '8px',
                                                  marginLeft: '7px',
                                                  marginRight: '7px',
                                                  marginBottom: '10px',
                                                  borderRadius: '4px',
                                                  border: '1px solid #ddd',
                                                  backgroundColor: isDarkMode
                                                    ? '#2b2b2b'
                                                    : '#fff',
                                                  color: isDarkMode
                                                    ? '#fff'
                                                    : '#000',
                                                  borderColor: isDarkMode
                                                    ? '#555'
                                                    : 'rgb(198 195 195)',
                                                  placeholderColor: isDarkMode
                                                    ? '#ccc'
                                                    : '#aaa',
                                                }}
                                              />
                                              <button
                                                onClick={() =>
                                                  handlePromptSubmit(
                                                    subtopic.topic_id
                                                  )
                                                }
                                                style={{
                                                  marginLeft: '10px',
                                                  marginBottom: '10px',
                                                  padding: '4px 18px',
                                                  background: isDarkMode
                                                    ? 'rgba(255, 255, 255, 0.2)'
                                                    : '#2b2b2b',
                                                  color: isDarkMode
                                                    ? '#fff'
                                                    : '#fff',
                                                  border: `2px solid ${
                                                    isDarkMode
                                                      ? 'transparent'
                                                      : '#000'
                                                  }`,
                                                  fontSize: '15px',
                                                  borderRadius: '20px',
                                                  cursor: 'pointer',
                                                  fontFamily:
                                                    'Roboto, sans-serif',
                                                }}
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                        {/* File Upload */}
                                        {!IOA && (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              toggleUploadFields(module.id);
                                            }}
                                            // className="training-upload"
                                            style={{
                                              marginTop: '10px',
                                              marginLeft: '8px',
                                              marginBottom: '10px',
                                              textDecoration: 'underline',
                                              border: 'none',
                                              background: 'transparent',
                                              cursor: 'pointer',
                                              fontWeight: 'bold',
                                              color: isDarkMode
                                                ? '#fff'
                                                : '#000',
                                            }}
                                          >
                                            <i
                                              style={{
                                                marginRight: '10px',
                                              }}
                                              className={`fa-solid ${
                                                showUploadFields[module.id]
                                                  ? 'fa-times'
                                                  : 'fa-solid fa-arrow-up-from-bracket'
                                              }`}
                                            ></i>

                                            {showUploadFields[module.id]
                                              ? 'Close Upload Contents'
                                              : 'Upload Contents'}
                                          </button>
                                        )}
                                        {!IOA &&
                                          showUploadFields[module.id] && (
                                            <Form>
                                              <Form.Group
                                                controlId={`formText-${subtopic.topic_id}`}
                                                className="mb-3"
                                              >
                                                <Form.Label>
                                                  Enter your Contents
                                                </Form.Label>

                                                <textarea
                                                  className="contents-textarea"
                                                  placeholder={
                                                    KBValues[
                                                      subtopic.topic_id
                                                    ] ||
                                                    'Please enter specific Contents where Sofia excelled or could improve.'
                                                  }
                                                  onChange={(e) =>
                                                    setContentsText(
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    border:
                                                      '1px solid rgb(198 195 195)',

                                                    backgroundColor: isDarkMode
                                                      ? '#2b2b2b'
                                                      : '#fff',
                                                    color: isDarkMode
                                                      ? '#fff'
                                                      : '#000',
                                                    borderColor: isDarkMode
                                                      ? '#555'
                                                      : 'rgb(198 195 195)',
                                                    placeholderColor: isDarkMode
                                                      ? '#ccc'
                                                      : '#aaa',
                                                  }}
                                                ></textarea>
                                              </Form.Group>
                                              <Form.Group
                                                controlId={`formFile-${subtopic.topic_id}`}
                                                className="mb-3"
                                              >
                                                <Form.Label>
                                                  Upload File (PDF or
                                                  PowerPoint)
                                                </Form.Label>
                                                <Form.Control
                                                  type="file"
                                                  accept=".pdf, .ppt, .pptx"
                                                  style={{
                                                    backgroundColor: isDarkMode
                                                      ? '#2b2b2b'
                                                      : '#fff',
                                                    color: isDarkMode
                                                      ? '#fff'
                                                      : '#000',
                                                    borderColor: isDarkMode
                                                      ? '#555'
                                                      : '#ddd',
                                                  }}
                                                  onChange={(e) =>
                                                    setFile(e.target.files[0])
                                                  }
                                                />
                                              </Form.Group>

                                              <Form.Group
                                                controlId={`formUrl-${subtopic.topic_id}`}
                                                className="mb-3"
                                              >
                                                <Form.Label>
                                                  Knowledge URL
                                                </Form.Label>

                                                <input
                                                  type="url"
                                                  id="url"
                                                  className="url-textarea"
                                                  placeholder={
                                                    urlValues[
                                                      subtopic.topic_id
                                                    ] || 'https://example.com'
                                                  }
                                                  style={{
                                                    width: '100%',
                                                    border:
                                                      '1px solid rgb(198 195 195)',
                                                    paddingLeft: '7px',
                                                    padding: '5px',
                                                    backgroundColor: isDarkMode
                                                      ? '#2b2b2b'
                                                      : '#fff',
                                                    color: isDarkMode
                                                      ? '#fff'
                                                      : '#000',
                                                    borderColor: isDarkMode
                                                      ? '#555'
                                                      : 'rgb(198 195 195)',
                                                    placeholderColor: isDarkMode
                                                      ? '#ccc'
                                                      : '#aaa',
                                                  }}
                                                  onChange={(e) =>
                                                    setUrlText(e.target.value)
                                                  }
                                                ></input>
                                              </Form.Group>

                                              <Button
                                                variant="primary"
                                                onClick={() => {
                                                  handleKBUpload(
                                                    subtopic.topic_id,
                                                    contentsText,
                                                    urlText,
                                                    file
                                                  );
                                                  toggleUploadFields(module.id);
                                                }}
                                                className="submit-btn-training"
                                                style={{
                                                  padding: '4px 18px',
                                                  background: isDarkMode
                                                    ? 'rgba(255, 255, 255, 0.2)'
                                                    : '#2b2b2b',
                                                  color: isDarkMode
                                                    ? '#fff'
                                                    : '#fff',
                                                  border: `2px solid ${
                                                    isDarkMode
                                                      ? 'transparent'
                                                      : '#000'
                                                  }`,
                                                  borderRadius: '20px',
                                                  cursor: 'pointer',
                                                  fontFamily:
                                                    'Roboto, sans-serif',
                                                }}
                                              >
                                                Submit
                                              </Button>
                                            </Form>
                                          )}
                                        <div>
                                          {/* Feedback Section */}
                                          {!IOA && (
                                            <button
                                              className="view-student-feedback-btn"
                                              onClick={() => {
                                                handleFeedbackToggle(
                                                  subtopic.topic_id
                                                );
                                              }}
                                              style={{
                                                cursor: 'pointer',
                                                backgroundColor: 'transparent',
                                                padding: '6px 16px',
                                                marginLeft: '-3px',
                                                color: isDarkMode
                                                  ? '#fff'
                                                  : '#2b2b2b',
                                                borderRadius: '20px',
                                                fontSize: '16px',
                                                marginTop: '10px',
                                                border: 'none',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              <i
                                                className={`fa-solid ${
                                                  visibleFeedback[
                                                    subtopic.topic_id
                                                  ]
                                                    ? 'fa-chevron-up'
                                                    : 'fa-chevron-down'
                                                }`}
                                                style={{
                                                  paddingRight: '10px',
                                                }}
                                              ></i>
                                              {visibleFeedback[
                                                subtopic.topic_id
                                              ]
                                                ? 'Hide Direct Feedback'
                                                : 'Show Direct Feedback'}
                                            </button>
                                          )}
                                          {visibleFeedback[
                                            subtopic.topic_id
                                          ] && (
                                            <div
                                              style={{
                                                marginTop: '10px',
                                                padding: '10px',
                                                border: '1px solid #ddd',
                                                borderRadius: '8px',
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  paddingBottom: '10px',
                                                }}
                                              >
                                                Direct Feedback
                                              </h5>
                                              {feedbackData[subtopic.topic_id]
                                                ?.length > 0 ? (
                                                feedbackData[
                                                  subtopic.topic_id
                                                ].map((feedback, index) => (
                                                  <div
                                                    key={index}
                                                    style={{
                                                      marginBottom: '10px',
                                                    }}
                                                  >
                                                    <p>
                                                      <button
                                                        onClick={() =>
                                                          handleDeleteFeedback(
                                                            subtopic.topic_id,
                                                            feedback['upi'][0],
                                                            feedback[
                                                              'feedback'
                                                            ][0]
                                                          )
                                                        }
                                                        style={{
                                                          background:
                                                            'transparent',
                                                          position: 'right',
                                                          marginRight: '10px',
                                                          border: 'none',
                                                          color: isDarkMode
                                                            ? '#fff'
                                                            : '#2b2b2b',
                                                          cursor: 'pointer',

                                                          fontWeight: 'bold',
                                                        }}
                                                      >
                                                        <i class="fa-solid fa-xmark"></i>
                                                      </button>
                                                      <strong>
                                                        {feedback['upi']}:
                                                      </strong>{' '}
                                                      {feedback['feedback']}
                                                    </p>

                                                    <hr />
                                                  </div>
                                                ))
                                              ) : (
                                                <p>
                                                  No feedback available for this
                                                  subtopic.
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </div>{' '}
                                      </div>
                                    )}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : (
                          // If topic does not have a subtopic, display as ListGroup.Item (not expandable)
                          <Accordion.Item key={topicIndex}>
                            <ListGroup.Item
                              as="li"
                              style={{ padding: '0.75rem 1.25rem' }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {!IOA ? (
                                  <div style={{}}>
                                    {/* {topic.is_complete
                                      ? completedIcon
                                      : notCompletedIcon} */}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <span style={indexStyle}>
                                  {moduleIndex + 1}.{topicIndex + 1}
                                </span>
                                <div style={{ marginLeft: '1em' }}>
                                  <span
                                    onClick={async (e) => {
                                      if (IOA) {
                                        generateIOA(user.id, topic.topic_name);
                                      } else {
                                        if (
                                          settingTriggered[topic.topic_id] !==
                                          true
                                        ) {
                                          const { prompt, mode, KB, url } =
                                            await handleToggleSetting(topic);
                                          const userText = userInputRef.current;
                                          onSendMessageTopic(
                                            prompt,
                                            `I want to learn about ${topic.topic_name} in digital marketing.`,
                                            selectedCourse.id,
                                            user.id,
                                            userText,
                                            mode,
                                            KB,
                                            url,
                                            true
                                          );
                                        }
                                      }
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      color: 'inherit',
                                    }}
                                  >
                                    {topic.topic_name}
                                  </span>
                                </div>
                              </div>
                            </ListGroup.Item>
                          </Accordion.Item>
                        );
                      })}

                      {/* Oral Quiz */}
                      <Accordion.Item key={moduleIndex}>
                        <ListGroup.Item
                          as="li"
                          style={{ padding: '0.75rem 1.25rem' }}
                        >
                          {/*  */}
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div style={{}}>{/*  */}</div>
                            <div style={{ marginLeft: '1em' }}>
                              <span
                                className="module-text"
                                style={{
                                  cursor: 'pointer',
                                  color: '#2b2b2b',
                                  fontWeight: 'bold',
                                }}
                                onClick={async () => {
                                  try {
                                    let allTopics = Object.entries(
                                      studentProgress[module] || {}
                                    ).reduce((acc, [topic, subtopics]) => {
                                      acc.push(topic);

                                      if (Array.isArray(subtopics)) {
                                        subtopics.forEach((subtopic) => {
                                          acc.push(
                                            subtopic.topic_name || subtopic
                                          );
                                          if (
                                            Array.isArray(subtopic.subtopics)
                                          ) {
                                            subtopic.subtopics.forEach(
                                              (subSubtopic) => {
                                                acc.push(
                                                  subSubtopic.topic_name ||
                                                    subSubtopic
                                                );
                                              }
                                            );
                                          }
                                        });
                                      }

                                      return acc;
                                    }, []);

                                    const topics = allTopics
                                      .slice(1)
                                      .join(', ');
                                    //console.log('topics: ' + topics);

                                    const response = await fetch(
                                      `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-module-details/${studentProgress[module].module_id}`
                                    );
                                    const data = await response.json();
                                    setPassingScore(data.pass_rate);
                                    generateQuiz(
                                      user.id,
                                      { module }['module'],
                                      studentProgress[module].module_id,
                                      topics,
                                      data['no_of_questions'],
                                      data['pass_rate'],
                                      (score) =>
                                        handleQuizResult(
                                          score,
                                          module,
                                          studentProgress[module].module_id
                                        ),
                                      handleTranscript
                                    );
                                  } catch (error) {
                                    console.error('Error:', error);
                                  }
                                }}
                              >
                                Module {moduleIndex + 1}: Quiz
                              </span>
                            </div>
                          </div>
                        </ListGroup.Item>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              )
            )
          ) : null}
        </Accordion>
      </div>

      {Object.keys(studentProgress).length > 0 && (
        <div
          className="myProgress-soulMachines-container"
          style={{ flex: '1', marginTop: '-30px' }}
        >
          {' '}
          {showButtons ? <></> : null}
          <div className="myProgress-layout2" ref={layoutRef}>
            <div
              style={{ height: '1.4em', minWidth: '100%', maxHeight: '80vh' }}
            ></div>

            <div
              className="video-container2"
              style={{ margin: '0', position: 'relative', paddingTop: '0px' }}
            >
              {' '}
              <img
                src={logo}
                alt="Loading"
                class="breathing-img loading-screen"
                id="loadingimg"
                ref={loadingImageRef}
                style={{
                  display: contentLoading ? 'block' : 'none',
                }}
              />
              <video style={{}} ref={videoRef} id="sm-video" width="100%">
                Soul Machines Video
              </video>
              <button
                type="button"
                id="muteButton"
                onClick={handleMuteClick}
                ref={muteButtonRef}
                data-tooltip={!isMuted ? 'Finish speaking' : 'Start speaking'}
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                  background: 'none',
                  border: 'none',
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                {isMuted ? (
                  <FaMicrophoneSlash size={24} />
                ) : (
                  <FaMicrophone size={24} />
                )}
              </button>
              <div ref={speechIndicatorRef} id="speechIndicator">
                {isSpeaking && (
                  <div className="wave-bars2">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="myProgress-chat-container"
              style={{ margin: '10px 0' }}
            >
              <div
                id="chatbox2"
                ref={chatBoxRef}
                style={{ height: '300px' }}
              ></div>

              <div className="myProgress-input-container">
                <input
                  type="text"
                  id="userInputAI"
                  ref={userInputRef}
                  placeholder="Type your message"
                  onKeyDown={handleSendMessageEnter}
                />
                <button
                  type="button"
                  ref={sendButtonRef}
                  id="send_message_ai"
                  onClick={handleSendMessageClick}
                >
                  Send
                </button>
                <button
                  type="button"
                  id="interrupt_avatar"
                  ref={interruptButtonRef}
                  onClick={interrupt}
                  style={{ position: 'relative' }}
                  data-tooltip="Interrupt"
                >
                  <i class="fa-solid fa-stop"></i>
                </button>
                <button
                  type="button"
                  id="reconnect"
                  ref={reconnectButtonRef}
                  onClick={() => handleConnectClick(IOA)}
                  style={{ display: 'none', position: 'relative' }}
                  data-tooltip="Refresh"
                >
                  <i class="fa-solid fa-arrows-rotate"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <>
        <QuizPassModal
          isOpen={isPassModalOpen}
          onClose={handleClosePassModal}
          score={quizResult?.score ?? -1}
          transcript={transcript}
        />
        <QuizFailModal
          isOpen={isFailModalOpen}
          onClose={handleCloseFailModal}
          score={quizResult?.score ?? -1}
          transcript={transcript}
        />
      </>
    </div>
  );
};

export default TrainingGroundInst;
