/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';
import {
  OverlayTrigger,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';
import EngagementChart from './EngagementChart';
import { DarkModeContext } from '../context/DarkModeContext';

import './dashboard.css';

const CourseProgress = ({ courseId, courseData }) => {
  const [engagementData, setEngagementData] = useState([]);
  const [completionRateData, setCompletionRateData] = useState([]);
  const [expandedModules, setExpandedModules] = useState({});
  const [topModules, setTopModules] = useState([]);
  const [topTopics, setTopTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedModule, setSelectedModule] = useState(null);
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    setEngagementData([]);
    setCompletionRateData([]);
    setTopModules([]);
    setTopTopics([]);
    setExpandedModules({});
    setSelectedModule(null);
    setLoading(true);

    if (courseData) {
      prepareEngagementData(courseData);
      prepareCompletionRateData(courseData.modules);
      calculateTopModules(courseData.modules);
      calculateTopTopics(courseData.modules);
    }
    setLoading(false);
  }, [courseData, courseId]);

  const handleToggleModule = (moduleId) => {
    setExpandedModules((prevState) => ({
      ...prevState,
      [moduleId]: !prevState[moduleId],
    }));
    setSelectedModule(moduleId === selectedModule ? null : moduleId);
  };

  const prepareEngagementData = (courseData) => {
    if (!courseData.modules || !Array.isArray(courseData.modules)) return;

    const labels = courseData.modules.map(
      (module, index) => `${index + 1}. ${module.module_name}`
    );
    const chartData = courseData.modules.map((module) =>
      ((module.students_started / courseData.total_students) * 100).toFixed(2)
    );

    setEngagementData({ labels, chartData });
  };

  const prepareCompletionRateData = (modules) => {
    const labels = modules.map(
      (module, index) => `${index + 1}. ${module.module_name}`
    );
    const chartData = modules.map((module) =>
      module.avg_completion_rate.toFixed(2)
    );

    setCompletionRateData({ labels, chartData });
  };

  const calculateTopModules = (modules) => {
    const sortedModules = [...modules]
      .map((module, index) => ({
        moduleNumber: index + 1,
        moduleName: module.module_name,
        avgVisits: module.avg_visits,
      }))
      .filter((module) => module.avgVisits > 0)
      .sort((a, b) => b.avgVisits - a.avgVisits);

    setTopModules(sortedModules.slice(0, 5));
  };

  const calculateTopTopics = (modules) => {
    const topics = [];

    modules.forEach((module, moduleIndex) => {
      module.topics.forEach((topic, topicIndex) => {
        topics.push({
          moduleNumber: moduleIndex + 1,
          topicNumber: topicIndex + 1,
          topicName: topic.topic_name,
          avgVisits: topic.avg_visits,
        });
      });
    });

    const sortedTopics = topics
      .filter((topic) => topic.avgVisits > 0)
      .sort((a, b) => b.avgVisits - a.avgVisits);

    setTopTopics(sortedTopics.slice(0, 5));
  };

  if (!courseData || !courseData.modules || courseData.modules.length === 0) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: '#777777', fontSize: '18px', marginBottom: '0' }}>
          No course progress data available
        </p>
      </div>
    );
  }

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{title}</Tooltip>}
    >
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="course-progress" style={{ textAlign: 'left' }}>
      {courseData && (
        <>
          <Container fluid style={{ margin: '0' }}>
            <p
              style={{
                color: '#666666',
                paddingLeft: '0',
                fontSize: '21px',
                fontWeight: 'bold',
                margin: '0',
              }}
            >
              Course Progress
            </p>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <h5 className="mt-4">Top 5 Most Visited Modules</h5>
                <Table responsive striped bordered hover className="mt-2">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Module</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(5)].map((_, index) => {
                      const module = topModules[index];
                      return (
                        <tr
                          key={
                            module ? module.module_id : `top-module-${index}`
                          }
                        >
                          <td>{index + 1}</td>
                          <td>{module ? module.moduleNumber : ''}</td>
                          <td>{module ? module.moduleName : ''}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <h5 className="mt-4">Top 5 Most Visited Topics</h5>
                <Table responsive striped bordered hover className="mt-2">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Module</th>
                      <th>Topic</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(5)].map((_, index) => {
                      const topic = topTopics[index];
                      return (
                        <tr key={topic ? topic.topic_id : `top-topic-${index}`}>
                          <td>{index + 1}</td>
                          <td>{topic ? topic.moduleNumber : ''}</td>
                          <td>{topic ? topic.topicNumber : ''}</td>
                          <td>{topic ? topic.topicName : ''}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} xs={12}>
                <Table responsive striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>
                        Engagement Rate (%){' '}
                        <Link title="Engagement Rate (%) indicates the percentage of students who have started this module/topic compared to the total number of students enrolled in the course.">
                          <i className="fa-solid fa-circle-info"></i>
                        </Link>
                      </th>
                      <th>
                        Average Visits{' '}
                        <Link title="Visits refer to the number of clicks a student makes on topics and subtopics.">
                          <i className="fa-solid fa-circle-info"></i>
                        </Link>
                      </th>
                      <th>
                        Completion Rate (%){' '}
                        <Link title="The Completion Rate is the percentage of students who have finished the module, calculated as the number of students who completed the module divided by the total number of students.">
                          <i className="fa-solid fa-circle-info"></i>
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseData.modules.map((module, moduleIndex) => (
                      <React.Fragment key={module.module_id}>
                        <tr
                          onClick={() => handleToggleModule(module.module_id)}
                          style={{
                            cursor: 'pointer',
                            backgroundColor:
                              selectedModule === module.module_id
                                ? 'rgba(147, 112, 219, 0.2) !important'
                                : 'transparent',
                            borderBottom:
                              selectedModule === module.module_id
                                ? '2px solid #930067'
                                : 'none',
                          }}
                        >
                          <td>
                            <strong>
                              {moduleIndex + 1}. {module.module_name}
                            </strong>
                          </td>
                          <td
                            style={{
                              color: isDarkMode
                                ? 'rgba(227 ,122 ,200 , 0.8)'
                                : 'rgba(139, 0, 103, 0.8)',
                              fontWeight: 'bold',
                            }}
                          >
                            {(
                              (module.students_started /
                                courseData.total_students) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                          <td
                            style={{
                              color: isDarkMode
                                ? 'rgba(227 ,122 ,200 , 0.8)'
                                : 'rgba(139, 0, 103, 0.8)',
                              fontWeight: 'bold',
                            }}
                          >
                            {module.avg_visits.toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: isDarkMode
                                ? 'rgba(227 ,122 ,200 , 0.8)'
                                : 'rgba(139, 0, 103, 0.8)',
                              fontWeight: 'bold',
                            }}
                          >
                            {module.avg_completion_rate.toFixed(2)}%
                          </td>
                        </tr>
                        {expandedModules[module.module_id] && (
                          <>
                            {module.topics.map((topic, topicIndex) => (
                              <tr key={topic.topic_id}>
                                <td style={{ paddingLeft: '2em' }}>
                                  {topicIndex + 1}. {topic.topic_name}
                                </td>
                                <td>
                                  {(
                                    (topic.students_started /
                                      courseData.total_students) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>{topic.avg_visits.toFixed(2)}</td>
                                <td>{topic.avg_completion_rate.toFixed(2)}%</td>
                              </tr>
                            ))}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>

                <Table responsive striped bordered hover className="mt-3">
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'right',
                          paddingRight: '10px',
                        }}
                      >
                        Overall Average Visits
                      </td>
                      <td>{courseData.overall_avg_visits.toFixed(2)} visits</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'right',
                          paddingRight: '10px',
                        }}
                      >
                        Overall Completion Rate
                      </td>
                      <td>
                        {courseData.overall_avg_completion_rate.toFixed(2)}%
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'right',
                          paddingRight: '10px',
                        }}
                      >
                        Overall Average Time Spent
                      </td>
                      <td>
                        {courseData.overall_avg_time_spent_minutes.toFixed(2)}{' '}
                        minutes
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} xs={12}>
                {engagementData.labels && engagementData.chartData && (
                  <EngagementChart
                    key={courseId + '-engagementProgress'}
                    labels={engagementData.labels}
                    chartData={engagementData.chartData}
                    title="Engagement Rates"
                    label="Modules"
                    text="Engagemnent Rate (%)"
                  />
                )}
              </Col>
              <Col lg={6} md={6} xs={12}>
                {completionRateData.labels && completionRateData.chartData && (
                  <EngagementChart
                    key={courseId + '-completion'}
                    labels={completionRateData.labels}
                    chartData={completionRateData.chartData}
                    title="Average Completion Rates"
                    label="Modules"
                    text="Average Completion Rate (%)"
                  />
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default CourseProgress;
