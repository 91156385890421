import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Tabs, Tab, Row, Col } from 'react-bootstrap';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import InstructorNavBar from '../components/InstructorNavBar';
import ProfileModal from '../components/ProfileModal';
import DashboardAnalytics from '../components/DashboardAnalytics';
import CourseStudentList from '../components/CourseStudentList';
import NotificationBell from '../components/NotificationBell';

import { DarkModeContext } from '../context/DarkModeContext';
import './style.css';

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSemesterYear, setSelectedSemesterYear] = useState(null);
  const [activeTab, setActiveTab] = useState('analytics');
  const { isDarkMode } = useContext(DarkModeContext);

  const handleCourseChange = (courseNameAndCode) => {
    setSelectedCourse(courseNameAndCode);
    const matchingCourses = courses.filter(
      (course) =>
        `${course.course_name} ${course.course_code}` === courseNameAndCode
    );
    setSelectedSemesterYear(matchingCourses[0]);
  };

  const handleSemesterYearChange = (courseId) => {
    const course = courses.find((c) => c.id.toString() === courseId);
    setSelectedSemesterYear(course);
  };

  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/instructor/${user.id}`
        );
        if (response.ok) {
          const data = await response.json();
          setCourses(data.courses);
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchMyCourses();
  }, [user.id]);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const courseNamesAndCodes = Array.from(
    new Set(
      courses.map((course) => `${course.course_name} ${course.course_code}`)
    )
  );

  return (
    <div className="instructor-dashboard-container" id="dashboard-container">
      <div className="instructor-view-top-container">
        <div className="logo-container">
          <NavLink to="/manage">
            <img
              src={
                isDarkMode ? '/Sofia-logo-white.png' : '/Sofia-logo-colour.png'
              }
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <InstructorNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                referrerPolicy="no-referrer"
                className="profile-img"
                alt=""
              />
            ) : (
              <i
                className="fa-solid fa-user"
                style={{ fontSize: '25px', paddingRight: '15px' }}
              ></i>
            )}
            <span>{user.username}</span>
          </button>
          <NotificationBell />
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="content-container">
        <p className="page-title">Dashboard</p>

        <Form className="mt-3 mb-1">
          <Row>
            <Col xs={12} md={3}>
              <Form.Select
                className="form-select"
                aria-label="Select Course"
                onChange={(e) => handleCourseChange(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <option value="" hidden>
                  Select a course
                </option>
                {courseNamesAndCodes.map((courseNameAndCode) => (
                  <option key={courseNameAndCode} value={courseNameAndCode}>
                    {courseNameAndCode}
                  </option>
                ))}
              </Form.Select>
            </Col>

            {selectedCourse && (
              <Col xs={12} md={3}>
                <Form.Select
                  aria-label="Select Semester and Year"
                  onChange={(e) => handleSemesterYearChange(e.target.value)}
                  value={selectedSemesterYear ? selectedSemesterYear.id : ''}
                  style={{ width: '100%', marginBottom: '20px' }}
                >
                  <option value="" hidden>
                    Select Semester and Year
                  </option>
                  {courses
                    .filter(
                      (course) =>
                        `${course.course_name} ${course.course_code}` ===
                        selectedCourse
                    )
                    .map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.semester <= 4
                          ? `Q${course.semester}`
                          : course.semester === 5
                          ? 'S1'
                          : 'S2'}{' '}
                        {course.year}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            )}
          </Row>
        </Form>

        {selectedSemesterYear && (
          <Tabs
            id="dashboard-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab eventKey="analytics" title="Analytics">
              <DashboardAnalytics course={selectedSemesterYear} />
            </Tab>
            <Tab eventKey="students" title="Students">
              <CourseStudentList courseId={selectedSemesterYear.id} />
            </Tab>
          </Tabs>
        )}
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default Dashboard;
