/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { Accordion, Form, ListGroup, Row, Col } from 'react-bootstrap';
import { UserContext } from '../context/UserContext';
import '../pages/style.css';
//soul machine
import {
  onSendMessageTopic,
  onSendMessage,
  toggleIOA,
  generateQuiz,
  generateIOA,
  updateAIData,
  initializeAI,
} from './SoulMachines';
//heygen
import {
  initializeAvatarSession,
  handleInitialSpeak,
  handleChangeChatMode,
  initializeEventSystem,
  interrupt,
  isStreamDisconnected,
  handleMuteStatus,
} from './heygen/main.ts';

import './accordion.css';
import QuizPassModal from './QuizPassModal';
import QuizFailModal from './QuizFailModal';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import logo from './Sofia-logo-white.png';
import { DarkModeContext } from '../context/DarkModeContext';

const CourseContent = ({ selectedCourse }) => {
  const [isPinned, setIsPinned] = useState(false);
  const { user } = useContext(UserContext);
  const [contentLoading, setContentLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [studentProgress, setStudentProgress] = useState({});
  const [lastActionTime, setLastActionTime] = useState(0);
  const [passingScore, setPassingScore] = useState(null);
  const [quizResult, setQuizResult] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [isQuizResultProcessed, setIsQuizResultProcessed] = useState(false);
  const [isPassModalOpen, setIsPassModalOpen] = useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);
  const [IOA, setIsIOA] = useState(false);
  const [showContent, setDisplayContent] = useState(true);
  const { isDarkMode } = useContext(DarkModeContext);
  const inputRef = useRef(null);

  const [isSpeaking, setIsSpeaking] = useState(false);
  useEffect(() => {
    // Initialize the event system with the setState function
    initializeEventSystem(setIsSpeaking);
  }, []);

  const videoRef = useRef(null);
  const chatBoxRef = useRef(null);
  const userInputRef = useRef(null);
  const sendButtonRef = useRef(null);
  const reconnectButtonRef = useRef(null);
  const muteButtonRef = useRef(null);
  const interruptButtonRef = useRef(null);
  const speechIndicatorRef = useRef(null);

  const loadingImageRef = useRef(null);
  const courseContentListRef = useRef([]);

  const location = useLocation();

  const handleOpenPassModal = () => setIsPassModalOpen(true);
  const handleOpenFailModal = () => setIsFailModalOpen(true);
  const handleClosePassModal = () => setIsPassModalOpen(false);
  const handleCloseFailModal = () => setIsFailModalOpen(false);
  const [placeholderVisible, setPlaceholderVisible] = useState({});
  const [placeholderInput, setPlaceholderInput] = useState({});
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 650);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleUserInput = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (isModalOpen && event.key.length === 1) {
        handleUserInput();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (!inputRef.current) return;

    const hasGeneratingMessage = Object.values(placeholderInput || {}).some(
      (text) => text?.toLowerCase().includes('sofia is generating response')
    );

    if (hasGeneratingMessage) {
      inputRef.current.classList.add('blinking-border');
    } else {
      inputRef.current.classList.remove('blinking-border');
    }
  }, [placeholderInput]);
  // const [editingSubtopicId, setEditingSubtopicId] = useState(null); // Tracks the currently editing subtopic
  // const [fetchedPrompts, setFetchedPrompts] = useState({}); // Stores fetched prompts for each subtopic
  // const [promptTextData, setPromptTextData] = useState({}); // Stores prompt text for each subtopic during editing

  //start message
  const veryStartMessageLearning =
    'Hello! How can I assist you with digital marketing today? You can navigate the course content using the menu on the left and ask any questions you may have—I’m here to provide the most accurate and helpful answers. To interact with me, you can click the unmute icon to speak and the mute icon when you’re finished. Alternatively, feel free to type your questions directly. Once you’re confident with the course material, you can test your knowledge by attempting the quiz available in the left menu. Let’s get started!';

  const startMessageLearning =
    'Hi there, what area of digital marketing can I help you with today?';
  const startMessageIOA =
    'Hi there, welcome the Interactive oral assessment page. Please select the topic you want to cover in this interactive oral assessment.';

  function handleQuizResult(score, moduleName, moduleId) {
    setIsQuizResultProcessed(false);
    setQuizResult({ score, moduleName, moduleId });
  }
  const handleSendFeedback = async (topicId) => {
    const feedback = placeholderInput[topicId];
    if (feedback) {
      const id = sessionStorage.getItem('id');
      try {
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/users/post-direct-feedback`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: id,
              feedbackText: feedback,
              topicId: topicId,
            }),
          }
        );
        alert(`Your feedback sent successfully!`);
      } catch (error) {
        console.error('Error sending feedbacks:', error);
      }
      //console.log(`Feedback for ${topicId}: ${feedback}`); // Replace with your actual send logic

      // Reset the feedback input
      setPlaceholderInput((prevState) => ({
        ...prevState,
        [topicId]: '',
      }));

      // Hide the placeholder after sending
      togglePlaceholder(topicId);
    } else {
      alert('Please enter feedback before sending.');
    }
  };

  const handlePlaceholderChange = (subtopicId, value) => {
    setPlaceholderInput((prevState) => ({
      ...prevState,
      [subtopicId]: value,
    }));
  };

  const handleTranscript = (transcript) => {
    setTranscript(transcript);
  };
  const togglePlaceholder = (subtopicId) => {
    setPlaceholderVisible((prevState) => ({
      ...prevState,
      [subtopicId]: !prevState[subtopicId],
    }));
  };

  useEffect(() => {
    if (quizResult && passingScore !== null && !isQuizResultProcessed) {
      const { score, moduleName } = quizResult;

      if (score >= passingScore) {
        handleOpenPassModal();

        setStudentProgress((prevState) => {
          const updatedProgress = { ...prevState };
          const moduleData = updatedProgress[moduleName];

          if (moduleData) {
            updatedProgress[moduleName].quiz_passed = true;

            const allTopicsComplete = moduleData.topics.every(
              (topic) => topic.is_complete
            );

            if (allTopicsComplete && !moduleData.is_complete) {
              updatedProgress[moduleName].is_complete = true;
            }
          }
          return updatedProgress;
        });
      } else {
        handleOpenFailModal();
      }
      setIsQuizResultProcessed(true);
    }
  }, [quizResult, passingScore, isQuizResultProcessed]);

  const handlePinClick = () => {
    setIsPinned((prev) => !prev);
    console.log('isPinned state changed:', !isPinned);
    if (!isPinned) {
      setDisplayContent(true);
    }
  };

  useEffect(() => {
    if (isPinned) {
      setDisplayContent(true);
    }
  }, [isPinned]);

  useEffect(() => {
    const fetchStudentProgress = async () => {
      if (selectedCourse) {
        // setContentLoading(true);
        try {
          const progressResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-progress/${selectedCourse.id}/${user.id}`
          );

          if (progressResponse.ok) {
            const progressData = await progressResponse.json();
            setStudentProgress(progressData.data);
          } else {
            console.error('Error fetching student progress');
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          // setContentLoading(false);
        }
      } else {
        setStudentProgress({});
      }
    };

    fetchStudentProgress();
    handleConnectClick();

    const handleBeforeUnload = (event) => {
      //heygen
      const { StreamDisconnected, totalConversationTime } =
        isStreamDisconnected();
      if (!StreamDisconnected) {
        updateAIData(selectedCourse.id, user.id, totalConversationTime);
      }
      const message =
        'You have unsaved changes. Are you sure you want to leave?';
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    const loadingimg = loadingImageRef.current;
    if (loadingimg) {
      loadingimg.style.display = 'none';
    }

    const handleResize = () => {
      const elements = document.getElementsByClassName('course-content-list');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = showContent ? 'block' : 'none';
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    window.addEventListener('beforeunload', handleBeforeUnload);

    window.hasUnsavedChanges = true;

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('resize', handleResize);
      //console.log('update');
    };
  }, [selectedCourse, user.id]);

  useEffect(() => {
    const handleResize = () => {
      const elements = document.getElementsByClassName('course-content-list');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = showContent ? 'block' : 'none';
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showContent]);

  useEffect(() => {
    //console.log('User is on route:', location.pathname);

    return () => {
      //console.log('User is leaving route:', location.pathname);

      const { StreamDisconnected, totalConversationTime } =
        isStreamDisconnected();
      if (!StreamDisconnected) {
        updateAIData(selectedCourse.id, user.id, totalConversationTime);
      }
      localStorage.removeItem('sessionId');
    };
  }, [location, selectedCourse.id, user.id]);

  const handleConnectClick = async (IOAimmediate) => {
    setContentLoading(true);
    //console.log('iteration');

    // if (!videoRef.current || !chatBoxRef.current) {
    //   console.warn('🚨 Video or ChatBox is not ready. Retrying...');
    //   setTimeout(() => handleConnectClick(IOAimmediate), 500); // 0.5 secs
    //   return;
    // }

    await new Promise((resolve) => setTimeout(resolve, 10));

    //console.log(videoRef.current);

    if (videoRef.current && chatBoxRef.current) {
      //HeyGen Connection
      const userInputHeyGen = userInputRef.current;
      const sendButtonHeyGen = sendButtonRef.current;
      const reconnectButton = reconnectButtonRef.current;
      const muteButton = muteButtonRef.current;
      const interruptButton = interruptButtonRef.current;
      const speechIndicator = speechIndicatorRef.current;
      const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
      const HeyGenApiKey = process.env.REACT_APP_HEYGEN_API_KEY;

      console.log(userInputRef.current);

      if (speechIndicator) speechIndicator.style.display = 'none';

      //console.log(openaiApiKey);
      //console.log(HeyGenApiKey);

      console.log(muteButton, speechIndicator);
      //console.log('IOA mode', IOAimmediate);

      try {
        initializeAI(
          videoRef.current,
          chatBoxRef.current,
          selectedCourse.id,
          user.id
        );

        const initialized = await initializeAvatarSession(
          videoRef.current,
          sendButtonHeyGen,
          userInputHeyGen,
          reconnectButton,
          muteButton,
          interruptButton,
          speechIndicator,
          openaiApiKey,
          HeyGenApiKey,
          IOAimmediate
        );

        if (initialized) {
          setShowButtons(false);
          setContentLoading(false);
          const loadingimg = loadingImageRef.current;
          loadingimg.style.display = 'none';
          handleInitialSpeak(
            IOAimmediate
              ? startMessageIOA
              : !selectedCourse.has_started
              ? veryStartMessageLearning
              : startMessageLearning
          );
        }
      } catch (error) {
        console.error('🔥 Error in handleConnectClick:', error);
      }
    }
  };

  const handleSendMessageClick = async () => {
    const userText = userInputRef.current;
    console.log(userText);
    if (userText) {
      await onSendMessage(userText);
      if (!isPinned) {
        setDisplayContent(false);
      }
      hideModulesTab();
    }
  };

  const handleSendMessageEnter = async (event) => {
    const userText = userInputRef.current;

    if (userText && event.key === 'Enter') {
      await onSendMessage(userText);
      if (!isPinned) {
        setDisplayContent(false);
      }
      hideModulesTab();
      event.preventDefault();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hideModulesTab = useCallback(() => {
    if (isPinned) return;

    setTimeout(() => {
      const modulesTab = document.getElementsByClassName('course-content-list');
      for (let i = 0; i < modulesTab.length; i++) {
        modulesTab[i].style.display = 'none';
      }
    }, 50);
  });

  const showModulesTab = () => {
    const modulesTab = document.getElementsByClassName('course-content-list');
    for (let i = 0; i < modulesTab.length; i++) {
      modulesTab[i].style.display = 'block';
    }
  };
  const handleAccordionClick = (event) => {
    if (
      event.target.closest('.feedback-button') ||
      event.target.closest('.feedback-row')
    ) {
      return;
    }

    if (!isPinned) {
      setDisplayContent(false);
      hideModulesTab();
    }
  };
  useEffect(() => {
    if (!isPinned) {
      if (!showContent) {
        hideModulesTab();
      } else {
        showModulesTab();
      }
    }
  }, [showContent, isPinned, hideModulesTab]);

  const handleSendMessageLearning = async (e, subtopic, courseid, userid) => {
    try {
      const userText = userInputRef.current;
      // Fetch the latest prompt for the subtopic
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/fetch-prompt/${subtopic.topic_id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const defaultPrompt = `I want to learn about ${subtopic.topic_name} in digital marketing.`; // Default fallback
      let fetchedPrompt;

      if (response.ok) {
        const result = await response.json();
        fetchedPrompt = result.prompts;
        //console.log(fetchedPrompt);
      }
      const finalTextInput = fetchedPrompt || defaultPrompt;
      // Fetching response mode
      let mode;

      const responseMode = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/fetch-study-mode/${subtopic.topic_id}`
      );

      if (responseMode.ok) {
        const result = await responseMode.json();
        //console.log('mode raw: ', result);
        mode = result.mode;

        if (mode === 'AI') {
          onSendMessageTopic(
            finalTextInput,
            defaultPrompt,
            courseid,
            userid,
            userText,
            mode,
            '',
            '',
            false
          );
        } else {
          // Fethching KB and url
          const responseKB = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/curriculum/fetch-knowledge-base/${subtopic.topic_id}`
          );

          let fetchedKB;
          let fetchedUrl;

          if (responseKB.ok) {
            const result = await responseKB.json();
            fetchedKB = result.knowledge_texts.text.join(', ') || '';
            fetchedUrl = result.knowledge_texts.url.join(', ') || '';
          }
          onSendMessageTopic(
            finalTextInput,
            defaultPrompt,
            courseid,
            userid,
            userText,
            mode,
            fetchedKB,
            fetchedUrl,
            false
          );
        }
      }
    } catch (error) {
      console.error('Error in handleSendMessageLearning:', error);
    }
  };

  const handleMuteClick = async () => {
    try {
      const bool = !isMuted;
      setIsMuted(bool);
      handleMuteStatus(bool);
      handleChangeChatMode(bool ? 'text_mode' : 'voice_mode');
      //console.log('mute status: ', !isMuted);
    } catch (error) {
      console.error('Error toggling mute:', error);
    }
  };
  const handleShowContent = () => {
    if (isPinned) {
      // change to default (gray)
      setIsPinned(false);
      setDisplayContent(false);
    } else {
      setDisplayContent(!showContent);
    }
    const elements = document.getElementsByClassName('course-content-list');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = showContent ? 'none' : 'block';
    }
  };

  const handleIOAswitch = async () => {
    const { StreamDisconnected, totalConversationTime } =
      isStreamDisconnected();
    if (!StreamDisconnected)
      updateAIData(selectedCourse.id, user.id, totalConversationTime);
    try {
      const IOAstatus = await toggleIOA(IOA);
      //console.log('IOA has changed to: ', IOAstatus);
      setIsIOA(IOAstatus);
      handleConnectClick(IOAstatus);
    } catch (error) {
      console.error('Error toggling IOA:', error);
    }
  };
  useEffect(() => {
    //console.log('Updated IOA is: ', IOA);
  }, [IOA]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [transcript, quizResult]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 650);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    } else {
      console.warn('🚨 chatBoxRef is not reset.');
    }
  }, [transcript, quizResult]);

  const handleCompletedClick = async (
    moduleName,
    topicId,
    subtopicId = null
  ) => {
    const currentTime = Date.now();
    if (currentTime - lastActionTime < 30000) {
      // 60,000 milliseconds = 1 minute
      console.warn(
        'You can only complete one topic or subtopic per one-half minute.'
      );
      return;
    }

    try {
      let apiUrl;
      if (subtopicId) {
        apiUrl = `${process.env.REACT_APP_BACKEND_URL}/curriculum/complete-topic/${subtopicId}/${user.id}`;
      } else {
        apiUrl = `${process.env.REACT_APP_BACKEND_URL}/curriculum/complete-topic/${topicId}/${user.id}`;
      }

      const moduleData = studentProgress[moduleName];
      const topicData = moduleData.topics.find(
        (topic) => topic.topic_id === topicId
      );

      if (subtopicId) {
        const subtopicData = topicData.subtopics.find(
          (subtopic) => subtopic.topic_id === subtopicId
        );
        if (!subtopicData) {
          console.error('Subtopic not found in progress data');
          return;
        }
        subtopicData.is_complete = true;

        const allSubtopicsComplete = topicData.subtopics.every(
          (subtopic) => subtopic.is_complete
        );
        if (allSubtopicsComplete) {
          topicData.is_complete = true;
        }
      } else {
        topicData.is_complete = true;
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setStudentProgress({ ...studentProgress });
        setLastActionTime(currentTime);
      } else {
        console.error('Error updating topic completion');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // if (contentLoading) {
  //   return (
  //     <div style={{ textAlign: 'center' }}>
  //       <Spinner animation="border" variant="secondary" />
  //     </div>
  //   );
  // }

  const indexStyle = { color: '#777777' };
  const iconStyle = {
    color: 'rgba(139, 0, 103, 0.4)',
    fontSize: '18px',
  };

  const completedIcon = (
    <i className="fa-solid fa-circle-check" style={iconStyle}></i>
  );
  const notCompletedIcon = (
    <i className="fa-regular fa-circle" style={iconStyle}></i>
  );

  return (
    <div className="course-content" style={{ display: 'flex' }}>
      <div ref={courseContentListRef} className="course-content-list">
        <Accordion className="mt-2 pb-3">
          <Accordion.Item className="pt-3 ps-3 pb-1 course-header">
            <h5>
              {selectedCourse.course_name} {selectedCourse.course_code}:{' '}
              {selectedCourse.description}
            </h5>
            <i
              className="fa-solid fa-thumbtack"
              style={{
                color: isDarkMode
                  ? isPinned
                    ? 'white'
                    : 'gray'
                  : isPinned
                  ? 'black'
                  : 'gray',
                cursor: 'pointer',
              }}
              onClick={handlePinClick}
            ></i>
          </Accordion.Item>
          {Object.keys(studentProgress).length === 0 ? (
            <Accordion.Item className="pt-3 ps-3 pb-1">
              <p>
                No content available for {selectedCourse.course_name}{' '}
                {selectedCourse.course_code}: {selectedCourse.description}
              </p>
            </Accordion.Item>
          ) : !showButtons ? (
            // Module
            Object.entries(studentProgress).map(
              ([module, moduleData], moduleIndex) => (
                <Accordion.Item
                  eventKey={`module-${moduleIndex}`}
                  key={moduleIndex}
                >
                  <Accordion.Header>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {!IOA ? (
                        <div style={{ marginRight: '1em' }}>
                          {studentProgress[module]?.is_complete
                            ? completedIcon
                            : notCompletedIcon}
                        </div>
                      ) : (
                        ''
                      )}
                      <span style={indexStyle}>{moduleIndex + 1}</span>
                      <div style={{ marginLeft: '1em' }}>{module}</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      {/* Topic */}
                      {moduleData.topics.map((topic, topicIndex) => {
                        const subtopics = topic.subtopics;
                        // If topic has at least 1 subtopic, display as accordion
                        return subtopics.length > 0 ? (
                          <Accordion.Item
                            eventKey={`topic-${moduleIndex}-${topicIndex}`}
                            key={topicIndex}
                          >
                            <Accordion.Header>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {!IOA ? (
                                  <div style={{ marginRight: '1em' }}>
                                    {topic.is_complete
                                      ? completedIcon
                                      : notCompletedIcon}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <span style={indexStyle}>
                                  {moduleIndex + 1}.{topicIndex + 1}
                                </span>
                                <div style={{ marginLeft: '1em' }}>
                                  {topic.topic_name}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <ListGroup as="ol">
                                {/* Subtopics displayed as ListGroup.Item (not expandable) */}
                                {subtopics.map((subtopic, subtopicIndex) => (
                                  <ListGroup.Item
                                    as="li"
                                    key={subtopicIndex}
                                    onClick={handleAccordionClick}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        // justifyContent: 'space-between',
                                      }}
                                    >
                                      {!IOA ? (
                                        <div style={{ marginRight: '1em' }}>
                                          {subtopic.is_complete
                                            ? completedIcon
                                            : notCompletedIcon}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      <span style={indexStyle}>
                                        {moduleIndex + 1}.{topicIndex + 1}.
                                        {subtopicIndex + 1}
                                      </span>
                                      <div style={{ marginLeft: '1em' }}>
                                        <span
                                          onClick={(e) => {
                                            if (IOA) {
                                              generateIOA(
                                                user.id,
                                                subtopic.topic_name
                                              );
                                              //console.log('IOA');
                                            } else {
                                              handleSendMessageLearning(
                                                e,
                                                subtopic,
                                                selectedCourse.id,
                                                user.id
                                              );
                                              //console.log('Learning');
                                              handleCompletedClick(
                                                module,
                                                topic.topic_id,
                                                subtopic.topic_id
                                              );
                                            }
                                          }}
                                          style={{
                                            cursor: 'pointer',
                                            color: 'inherit',
                                          }}
                                        >
                                          {subtopic.topic_name}
                                        </span>
                                      </div>
                                    </div>
                                    {/* placeholder */}
                                    <div
                                      style={{
                                        display: 'flex',
                                        padding: '5px',
                                      }}
                                    >
                                      <button
                                        className="feedback-button"
                                        style={{
                                          padding: '5px 15px',
                                          border: `1px solid ${
                                            isDarkMode
                                              ? 'rgba(255, 255, 255, 0.7)'
                                              : 'rgba(139, 0, 103, 0.7)'
                                          }`,
                                          background: 'none',
                                          color: isDarkMode
                                            ? '#fff'
                                            : 'rgba(139, 0, 103, 0.7)',
                                          cursor: 'pointer',
                                          borderRadius: '50px',
                                          marginLeft: 'auto',
                                        }}
                                        onClick={() =>
                                          togglePlaceholder(subtopic.topic_id)
                                        }
                                      >
                                        Feedback
                                      </button>{' '}
                                    </div>
                                    <div className="feedback-row">
                                      {placeholderVisible[
                                        subtopic.topic_id
                                      ] && (
                                        <div
                                          style={{
                                            marginTop: '10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                          }}
                                        >
                                          <textarea
                                            placeholder={
                                              "Please provide feedback on Sofia's response."
                                            }
                                            maxLength="100"
                                            value={
                                              placeholderInput[
                                                subtopic.topic_id
                                              ] || ''
                                            }
                                            onChange={(e) =>
                                              handlePlaceholderChange(
                                                subtopic.topic_id,
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              width: '100%',
                                              height: '100px',
                                              padding: '10px',
                                              borderRadius: '5px',
                                              border: `1px solid ${
                                                isDarkMode ? '#555' : '#ccc'
                                              }`,
                                              backgroundColor: isDarkMode
                                                ? '#333'
                                                : '#f9f9f9',
                                              color: isDarkMode
                                                ? '#fff'
                                                : '#000',
                                              fontSize: '14px',
                                              fontFamily: 'inherit',
                                              resize: 'none',
                                              overflowWrap: 'break-word',
                                              wordWrap: 'break-word',
                                              whiteSpace: 'pre-wrap',
                                              overflowY: 'auto',
                                              boxSizing: 'border-box',
                                            }}
                                          ></textarea>

                                          <button
                                            className="feedback-button"
                                            style={{
                                              padding: '5px 15px',
                                              background: isDarkMode
                                                ? 'rgba(255, 255, 255, 0.3)'
                                                : 'rgba(139, 0, 103, 0.7)',
                                              color: isDarkMode
                                                ? '#000'
                                                : '#fff',
                                              border: 'none',
                                              borderRadius: '50px',
                                              cursor: 'pointer',
                                              alignSelf: 'flex-end',
                                              fontFamily:
                                                "'Roboto', sans-serif",
                                            }}
                                            onClick={() =>
                                              handleSendFeedback(
                                                subtopic.topic_id
                                              )
                                            }
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      )}{' '}
                                    </div>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : (
                          // If topic does not have a subtopic, display as ListGroup.Item (not expandable)
                          <Accordion.Item
                            key={topicIndex}
                            onClick={handleAccordionClick}
                          >
                            <ListGroup.Item
                              as="li"
                              style={{ padding: '0.75rem 1.25rem' }}
                              key={topicIndex}
                              onClick={handleAccordionClick}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {!IOA ? (
                                  <div style={{ marginRight: '1em' }}>
                                    {topic.is_complete
                                      ? completedIcon
                                      : notCompletedIcon}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <span style={indexStyle}>
                                  {moduleIndex + 1}.{topicIndex + 1}
                                </span>
                                <div style={{ marginLeft: '1em' }}>
                                  <span
                                    onClick={(e) => {
                                      if (IOA) {
                                        generateIOA(user.id, topic.topic_name);
                                      } else {
                                        handleSendMessageLearning(
                                          e,
                                          topic,
                                          selectedCourse.id,
                                          user.id
                                        );
                                        handleCompletedClick(
                                          module,
                                          topic.topic_id
                                        );
                                      }
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      color: 'inherit',
                                    }}
                                  >
                                    {topic.topic_name}
                                  </span>
                                </div>
                              </div>
                            </ListGroup.Item>
                          </Accordion.Item>
                        );
                      })}

                      {/* Oral Quiz */}
                      <Accordion.Item key={moduleIndex}>
                        <ListGroup.Item
                          as="li"
                          style={{ padding: '0.75rem 1.25rem' }}
                          key={moduleIndex}
                          onClick={handleAccordionClick}
                        >
                          {!IOA ? (
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{ marginRight: '2.2em' }}>
                                  {studentProgress[module].quiz_passed
                                    ? completedIcon
                                    : notCompletedIcon}
                                </div>

                                <div style={{ marginLeft: '1em' }}>
                                  <span
                                    className="module-text"
                                    style={{
                                      cursor: 'pointer',
                                      color: isDarkMode ? '#fff' : '#2b2b2b',
                                      fontWeight: 'bold',
                                    }}
                                    onClick={async () => {
                                      try {
                                        let allTopics = Object.entries(
                                          studentProgress[module] || {}
                                        ).reduce((acc, [topic, subtopics]) => {
                                          acc.push(topic);

                                          if (Array.isArray(subtopics)) {
                                            subtopics.forEach((subtopic) => {
                                              acc.push(
                                                subtopic.topic_name || subtopic
                                              );
                                              if (
                                                Array.isArray(
                                                  subtopic.subtopics
                                                )
                                              ) {
                                                subtopic.subtopics.forEach(
                                                  (subSubtopic) => {
                                                    acc.push(
                                                      subSubtopic.topic_name ||
                                                        subSubtopic
                                                    );
                                                  }
                                                );
                                              }
                                            });
                                          }

                                          return acc;
                                        }, []);

                                        const topics = allTopics
                                          .slice(1)
                                          .join(', ');
                                        //console.log('topics: ' + topics);

                                        const response = await fetch(
                                          `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-module-details/${studentProgress[module].module_id}`
                                        );
                                        const data = await response.json();
                                        setPassingScore(data.pass_rate);
                                        generateQuiz(
                                          user.id,
                                          { module }['module'],
                                          studentProgress[module].module_id,
                                          topics,
                                          data['no_of_questions'],
                                          data['pass_rate'],
                                          (score) =>
                                            handleQuizResult(
                                              score,
                                              module,
                                              studentProgress[module].module_id
                                            ),
                                          handleTranscript
                                        );
                                      } catch (error) {
                                        console.error('Error:', error);
                                      }
                                    }}
                                  >
                                    Module {moduleIndex + 1}: Quiz
                                  </span>
                                </div>
                              </div>

                              {/* placeholder */}
                              {/* <div
                                style={{
                                  display: 'flex',
                                  padding: '5px',
                                }}
                              >
                                <button
                                  style={{
                                    padding: '5px 15px',
                                    border: `1px solid ${
                                      isDarkMode
                                        ? 'rgba(255, 255, 255, 0.7)'
                                        : 'rgba(139, 0, 103, 0.7)'
                                    }`,
                                    background: 'none',
                                    color: isDarkMode
                                      ? '#fff'
                                      : 'rgba(139, 0, 103, 0.7)',
                                    cursor: 'pointer',
                                    borderRadius: '50px',
                                    marginLeft: 'auto',
                                  }}
                                  onClick={() => togglePlaceholder(module)}
                                >
                                  Feedback
                                </button>{' '}
                              </div> */}
                              <div className="feedback-row">
                                {placeholderVisible[module] && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                    }}
                                  >
                                    <textarea
                                      placeholder={`Provide direct feedback for: ${module}. Highlight key points or suggestions.`}
                                      maxLength="100"
                                      value={placeholderInput[module] || ''}
                                      onChange={(e) =>
                                        handlePlaceholderChange(
                                          module,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        width: '100%',
                                        height: '100px',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: `1px solid ${
                                          isDarkMode ? '#555' : '#ccc'
                                        }`,
                                        backgroundColor: isDarkMode
                                          ? '#333'
                                          : '#f9f9f9',
                                        color: isDarkMode ? '#fff' : '#000',
                                        fontSize: '14px',
                                        fontFamily: 'inherit',
                                        resize: 'none',
                                        overflowWrap: 'break-word',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        overflowY: 'auto',
                                        boxSizing: 'border-box',
                                      }}
                                    ></textarea>

                                    {/* <button
                                      style={{
                                        padding: '5px 15px',
                                        background: isDarkMode
                                          ? 'rgba(255, 255, 255, 0.3)'
                                          : 'rgba(139, 0, 103, 0.7)',
                                        color: isDarkMode ? '#000' : '#fff',
                                        border: 'none',
                                        borderRadius: '50px',
                                        cursor: 'pointer',
                                        alignSelf: 'flex-end',
                                        fontFamily: "'Roboto', sans-serif",
                                      }}
                                      onClick={() => handleSendFeedback(module)}
                                    >
                                      Send
                                    </button> */}
                                  </div>
                                )}{' '}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {/* <div style={{ marginRight: '2.2em' }}>
                                    {studentProgress[module].quiz_passed
                                      ? completedIcon
                                      : notCompletedIcon}
                                  </div> */}
                                  <div style={{ marginLeft: '1em' }}>
                                    <span
                                      className="module-text"
                                      style={{
                                        cursor: 'pointer',
                                        color: '#2b2b2b',
                                        fontWeight: 'bold',
                                      }}
                                      onClick={async () => {
                                        try {
                                          let allTopics = Object.entries(
                                            studentProgress[module] || {}
                                          ).reduce(
                                            (acc, [topic, subtopics]) => {
                                              acc.push(topic);

                                              if (Array.isArray(subtopics)) {
                                                subtopics.forEach(
                                                  (subtopic) => {
                                                    acc.push(
                                                      subtopic.topic_name ||
                                                        subtopic
                                                    );
                                                    if (
                                                      Array.isArray(
                                                        subtopic.subtopics
                                                      )
                                                    ) {
                                                      subtopic.subtopics.forEach(
                                                        (subSubtopic) => {
                                                          acc.push(
                                                            subSubtopic.topic_name ||
                                                              subSubtopic
                                                          );
                                                        }
                                                      );
                                                    }
                                                  }
                                                );
                                              }
                                              return acc;
                                            },
                                            []
                                          );

                                          const topics = allTopics
                                            .slice(1)
                                            .join(', ');
                                          //console.log('topics: ' + topics);

                                          const response = await fetch(
                                            `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-module-details/${studentProgress[module].module_id}`
                                          );

                                          const data = await response.json();
                                          setPassingScore(data.pass_rate);
                                          generateQuiz(
                                            user.id,
                                            { module }['module'],
                                            studentProgress[module].module_id,
                                            topics,
                                            data['no_of_questions'],
                                            data['pass_rate'],
                                            (score) =>
                                              handleQuizResult(
                                                score,
                                                module,
                                                studentProgress[module]
                                                  .module_id
                                              ),
                                            handleTranscript
                                          );
                                        } catch (error) {
                                          console.error('Error:', error);
                                        }
                                      }}
                                    >
                                      Module {moduleIndex + 1}: IOA
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* {start} */}

                              <div style={{ display: 'flex', padding: '5px' }}>
                                <button
                                  style={{
                                    padding: '5px 15px',
                                    border: `1px solid ${
                                      isDarkMode
                                        ? 'rgba(255, 255, 255, 0.7)'
                                        : 'rgba(139, 0, 103, 0.7)'
                                    }`,
                                    background: 'none',
                                    color: isDarkMode
                                      ? '#fff'
                                      : 'rgba(139, 0, 103, 0.7)',
                                    cursor: 'pointer',
                                    borderRadius: '50px',
                                    marginLeft: 'auto',
                                  }}
                                  onClick={() => togglePlaceholder(module)}
                                >
                                  Feedback
                                </button>
                              </div>

                              <div className="feedback-row">
                                {placeholderVisible[module] && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                    }}
                                  >
                                    <textarea
                                      placeholder={`Provide direct feedback for: ${module}. Highlight key points or suggestions.`}
                                      maxLength="100"
                                      value={placeholderInput[module] || ''}
                                      onChange={(e) =>
                                        handlePlaceholderChange(
                                          module,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        width: '100%',
                                        height: '100px',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: `1px solid ${
                                          isDarkMode ? '#555' : '#ccc'
                                        }`,
                                        backgroundColor: isDarkMode
                                          ? '#333'
                                          : '#f9f9f9',
                                        color: isDarkMode ? '#fff' : '#000',
                                        fontSize: '14px',
                                        fontFamily: 'inherit',
                                        resize: 'none',
                                        overflowWrap: 'break-word',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        overflowY: 'auto',
                                        boxSizing: 'border-box',
                                      }}
                                    ></textarea>

                                    <button
                                      style={{
                                        padding: '5px 15px',
                                        background: isDarkMode
                                          ? 'rgba(255, 255, 255, 0.3)'
                                          : 'rgba(139, 0, 103, 0.7)',
                                        color: isDarkMode ? '#000' : '#fff',
                                        border: 'none',
                                        borderRadius: '50px',
                                        cursor: 'pointer',
                                        alignSelf: 'flex-end',
                                        fontFamily: "'Roboto', sans-serif",
                                      }}
                                      onClick={() => handleSendFeedback(module)}
                                    >
                                      Send
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            // {end}
                          )}
                        </ListGroup.Item>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              )
            )
          ) : null}
        </Accordion>
      </div>
      {Object.keys(selectedCourse).length > 0 && (
        <div
          className="myProgress-soulMachines-container"
          style={{ flex: '1', marginTop: '-30px' }}
        >
          <div className="myProgress-layout">
            <Row className="mt-1 me-1 justify-content-end">
              <Col xs="auto">
                <Form.Check
                  type="switch"
                  className="myProgress-switch-button"
                  label={
                    !IOA ? (
                      <>
                        <span className="long-label">
                          Interactive Oral Assessment
                        </span>
                        <span className="short-label">IOA</span>
                      </>
                    ) : (
                      'Learning and Quizzes'
                    )
                  }
                  checked={IOA}
                  onChange={() => handleIOAswitch()}
                />
              </Col>
            </Row>

            <button
              type="button"
              id="showContent"
              className="mt-1 me-1 justify-content-end"
              onClick={handleShowContent}
              style={{ color: isDarkMode ? 'white' : '#2b2b2b', zIndex: '1' }}
            >
              <i className="fa-solid fa-clipboard-list"></i>
              <span>Modules</span>
            </button>
            {/* ✅ 650px above */}
            {!isMobileView ? (
              <div className="container-box">
                {/* chatbox */}
                <div
                  className="myProgress-chat-container"
                  style={{
                    margin: '10px 0',
                    paddingRight: '5px',
                    width: '46rem',
                  }}
                >
                  <div id="chatbox" ref={chatBoxRef}></div>
                </div>

                {/* video */}
                <div
                  className="video-container"
                  style={{ margin: '0', position: 'relative' }}
                >
                  <img
                    src={logo}
                    alt="Loading"
                    ref={loadingImageRef}
                    className="breathing-img loading-screen"
                    id="loadingimg"
                    style={{ display: contentLoading ? 'block' : 'none' }}
                  />

                  <video ref={videoRef} id="sm-video" width="100%">
                    Soul Machines Video
                  </video>
                  <button
                    type="button"
                    id="muteButton"
                    ref={muteButtonRef}
                    onClick={handleMuteClick}
                    data-tooltip={
                      !isMuted ? 'Finish speaking' : 'Start speaking'
                    }
                    style={{
                      position: 'absolute',
                      bottom: '70px',
                      left: '10px',
                      background: 'none',
                      border: 'none',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    {isMuted ? (
                      <FaMicrophoneSlash size={24} />
                    ) : (
                      <FaMicrophone size={24} />
                    )}
                  </button>
                  <div ref={speechIndicatorRef} id="speechIndicator">
                    {isSpeaking && (
                      <div className="wave-bars">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                      </div>
                    )}
                  </div>
                  <div className="myProgress-input-container">
                    <input
                      type="text"
                      ref={userInputRef}
                      id="userInputAI"
                      placeholder="Type your message"
                      onKeyDown={handleSendMessageEnter}
                      onFocus={handleUserInput}
                    />
                    <button
                      type="button"
                      id="send_message_ai"
                      ref={sendButtonRef}
                      onClick={handleSendMessageClick}
                    >
                      Send
                    </button>
                    <button
                      type="button"
                      id="interrupt_avatar"
                      ref={interruptButtonRef}
                      onClick={interrupt}
                      style={{ position: 'relative' }}
                      data-tooltip="Interrupt"
                    >
                      <i class="fa-solid fa-stop"></i>
                    </button>
                    <button
                      type="button"
                      id="reconnect"
                      ref={reconnectButtonRef}
                      onClick={() => handleConnectClick(IOA)}
                      style={{ display: 'none', position: 'relative' }}
                      data-tooltip="Refresh"
                    >
                      <i class="fa-solid fa-arrows-rotate"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              // ✅ 650px below
              <>
                <div className="video-container">
                  <img
                    src={logo}
                    alt="Loading"
                    className="breathing-img loading-screen"
                    id="loadingimg"
                    ref={loadingImageRef}
                    style={{ display: contentLoading ? 'block' : 'none' }}
                  />

                  <video ref={videoRef} id="sm-video" width="100%">
                    Soul Machines Video
                  </video>
                  <button
                    type="button"
                    id="muteButton"
                    ref={muteButtonRef}
                    onClick={handleMuteClick}
                    data-tooltip={
                      !isMuted ? 'Finish speaking' : 'Start speaking'
                    }
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '10px',
                      background: 'none',
                      border: 'none',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    {isMuted ? (
                      <FaMicrophoneSlash size={24} />
                    ) : (
                      <FaMicrophone size={24} />
                    )}
                  </button>
                  <div ref={speechIndicatorRef} id="speechIndicator">
                    {isSpeaking && (
                      <div className="wave-bars" style={{ bottom: '2px' }}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="myProgress-chat-container"
                  style={{ margin: '10px 0' }}
                >
                  <div
                    id="chatbox2"
                    ref={chatBoxRef}
                    style={{ height: '300px' }}
                  ></div>
                  <div className="myProgress-input-container">
                    <input
                      type="text"
                      ref={userInputRef}
                      id="userInputAI"
                      placeholder="Type your message"
                      onKeyDown={handleSendMessageEnter}
                    />
                    <button
                      type="button"
                      id="send_message_ai"
                      ref={sendButtonRef}
                      onClick={handleSendMessageClick}
                    >
                      Send
                    </button>
                    <button
                      type="button"
                      id="interrupt_avatar"
                      ref={interruptButtonRef}
                      onClick={interrupt}
                      style={{ position: 'relative' }}
                      data-tooltip="Interrupt"
                    >
                      <i class="fa-solid fa-stop"></i>
                    </button>
                    <button
                      type="button"
                      id="reconnect"
                      ref={reconnectButtonRef}
                      onClick={() => handleConnectClick(IOA)}
                      style={{ display: 'none', position: 'relative' }}
                      data-tooltip="Refresh"
                    >
                      <i class="fa-solid fa-arrows-rotate"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <>
        <QuizPassModal
          isOpen={isPassModalOpen}
          onClose={handleClosePassModal}
          score={quizResult?.score ?? -1}
          transcript={transcript}
        />
        <QuizFailModal
          isOpen={isFailModalOpen}
          onClose={handleCloseFailModal}
          score={quizResult?.score ?? -1}
          transcript={transcript}
        />
      </>
    </div>
  );
};

export default CourseContent;
