/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import './Modal.css';

const ProfileModal = ({ isOpen, onClose }) => {
  const { user } = useContext(UserContext);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchMyCourses = async () => {
      if (!isOpen || courses.length > 0) return;

      let response;
      try {
        if (user.role === 'student') {
          response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/student/${user.id}`
          );
        } else if (user.role === 'instructor') {
          response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/instructor/${user.id}`
          );
        }

        if (response.ok) {
          const data = await response.json();
          setCourses(data.courses);
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchMyCourses();
  }, [isOpen, user.id, courses.length]);

  if (!isOpen) return null;

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="profile-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <i className="fa-solid fa-x"></i>
        </button>

        <h2 className="modal-title">Profile</h2>
        <table className="profile-table">
          <tbody>
            <tr>
              <td className="profile-label">Name</td>
              <td className="profile-value">{user.username}</td>
            </tr>
            <tr>
              <td className="profile-label">Email</td>
              <td className="profile-value">{user.email}</td>
            </tr>
            <tr>
              <td className="profile-label">Role</td>
              <td className="profile-value">{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</td>
            </tr>
            <tr>
              <td className="profile-label">Courses</td>
              <td className="profile-value">
                {courses.length === 0 ? (
                  <p>No courses available</p>
                ) : (
                  <p>
                    {courses
                      .map(
                        (course) =>
                          `${course.course_name} ${course.course_code}`
                      )
                      .join(', ')}
                  </p>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfileModal;
