import React, { useState, useEffect } from 'react';
import { Spinner, Accordion, ListGroup, Form } from 'react-bootstrap';
import './Modal.css';

const ContentModal = ({ isOpen, closeModal, course }) => {
  const [courseContent, setCourseContent] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [moduleDetails, setModuleDetails] = useState({});
  const [editModuleId, setEditModuleId] = useState(null);
  const [newNumberOfQuestions, setNewNumberOfQuestions] = useState('');
  const [newPassingScore, setNewPassingScore] = useState('');

  useEffect(() => {
    const fetchCourseContent = async () => {
      if (course) {
        setContentLoading(true);
        try {
          const contentResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-content/${course.id}`
          );

          if (contentResponse.ok) {
            const contentData = await contentResponse.json();
            setCourseContent(contentData.data);

            const moduleIds = Object.values(contentData.data).map(
              (module) => module.module_id
            );

            const detailsPromises = moduleIds.map((moduleId) =>
              fetch(
                `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-module-details/${moduleId}`
              )
                .then((res) => res.json())
                .then((data) => ({
                  moduleId: data.module_id,
                  numberOfQuestions: data.no_of_questions,
                  passingScore: data.pass_rate,
                }))
            );

            const detailsResults = await Promise.all(detailsPromises);
            const detailsMap = detailsResults.reduce(
              (acc, { moduleId, numberOfQuestions, passingScore }) => {
                acc[moduleId] = { numberOfQuestions, passingScore };
                return acc;
              },
              {}
            );

            setModuleDetails(detailsMap);
          } else {
            console.error('Error fetching course content');
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setContentLoading(false);
        }
      } else {
        setCourseContent({});
        setModuleDetails({});
      }
    };

    fetchCourseContent();
  }, [course]);

  const handleEditClick = (moduleId, numberOfQuestions, passingScore) => {
    setEditModuleId(moduleId);
    setNewNumberOfQuestions(numberOfQuestions);
    setNewPassingScore(passingScore);
  };

  const handleSaveChanges = async () => {
    if (editModuleId === null) return;

    if (
      newNumberOfQuestions === '' ||
      Number(newNumberOfQuestions) < 1 ||
      Number(newNumberOfQuestions) > 50
    ) {
      alert('Please enter a valid number of questions between 1 and 50.');
      return;
    }

    if (
      newPassingScore === '' ||
      Number(newPassingScore) < 0 ||
      Number(newPassingScore) > 100
    ) {
      alert('Please enter a valid passing score between 0 and 100.');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/update-module-details/${editModuleId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            no_of_questions: newNumberOfQuestions,
            pass_rate: newPassingScore,
          }),
        }
      );

      if (response.ok) {
        alert('Module details updated successfully!');

        setModuleDetails((prev) => ({
          ...prev,
          [editModuleId]: {
            numberOfQuestions: newNumberOfQuestions,
            passingScore: newPassingScore,
          },
        }));

        setEditModuleId(null);
        setNewNumberOfQuestions('');
        setNewPassingScore('');
      } else {
        const data = await response.json();
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error updating module details');
    }
  };

  const deleteContent = async (courseId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete the course content? This action may also affect student progress.'
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/delete-content/${courseId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 204) {
        alert('Content deleted successfully!');
        setCourseContent({});
      } else {
        const data = await response.json();
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error deleting content:', error);
      alert('Error deleting content');
    }
  };

  if (!isOpen) return null;

  const indexStyle = { color: '#777777' };

  return (
    <div className="modal-background" onClick={closeModal}>
      <div
        className="content-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={closeModal}>
          <i className="fa-solid fa-x"></i>
        </button>

        {contentLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <div className="course-content-inst-view">
            <Accordion className="mt-3 pb-3">
              <Accordion.Item className="pt-3 ps-3 pb-1">
                <h5>
                  {course.course_name} {course.course_code}:{' '}
                  {course.description}
                </h5>
              </Accordion.Item>
              {Object.keys(courseContent).length === 0 ? (
                <Accordion.Item className="pt-3 ps-3 pb-1">
                  <p>
                    No content available for {course.course_name}{' '}
                    {course.course_code}: {course.description}
                  </p>
                </Accordion.Item>
              ) : (
                // Module
                Object.entries(courseContent).map(
                  ([module, moduleData], moduleIndex) => (
                    <Accordion.Item
                      eventKey={`module-${moduleIndex}`}
                      key={moduleIndex}
                    >
                      <Accordion.Header>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={indexStyle}>{moduleIndex + 1}</span>
                          <div style={{ marginLeft: '1em' }}>{module}</div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion>
                          {Object.keys(moduleData)
                            .filter(
                              (key) => key !== 'module_id' && key !== 'topics'
                            )
                            .map((topic, topicIndex) => {
                              const subtopics = moduleData[topic];
                              return subtopics.length > 0 ? (
                                <Accordion.Item
                                  eventKey={`topic-${moduleIndex}-${topicIndex}`}
                                  key={topicIndex}
                                >
                                  <Accordion.Header>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <span style={indexStyle}>
                                        {moduleIndex + 1}.{topicIndex + 1}
                                      </span>
                                      <div style={{ marginLeft: '1em' }}>
                                        {topic}
                                      </div>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ListGroup as="ol">
                                      {subtopics.map(
                                        (subtopic, subtopicIndex) => (
                                          <ListGroup.Item
                                            as="li"
                                            key={subtopicIndex}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <span style={indexStyle}>
                                                {moduleIndex + 1}.
                                                {topicIndex + 1}.
                                                {subtopicIndex + 1}
                                              </span>
                                              <div
                                                style={{ marginLeft: '1em' }}
                                              >
                                                <span
                                                  style={{ color: 'inherit' }}
                                                >
                                                  {subtopic}
                                                </span>
                                              </div>
                                            </div>
                                          </ListGroup.Item>
                                        )
                                      )}
                                    </ListGroup>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ) : (
                                <Accordion.Item key={topicIndex}>
                                  <ListGroup.Item
                                    as="li"
                                    style={{ padding: '0.75rem 1.25rem' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <span style={indexStyle}>
                                        {moduleIndex + 1}.{topicIndex + 1}
                                      </span>
                                      <div style={{ marginLeft: '1em' }}>
                                        <span style={{ color: 'inherit' }}>
                                          {topic}
                                        </span>
                                      </div>
                                    </div>
                                  </ListGroup.Item>
                                </Accordion.Item>
                              );
                            })}
                        </Accordion>
                        <div className="oral-quiz-setting">
                          <ListGroup horizontal className="mt-3">
                            <ListGroup.Item>
                              <b>Number of Questions</b>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {editModuleId === moduleData.module_id ? (
                                <Form.Control
                                  type="number"
                                  value={newNumberOfQuestions}
                                  min="1"
                                  max="50"
                                  onChange={(e) =>
                                    setNewNumberOfQuestions(e.target.value)
                                  }
                                  isInvalid={
                                    newNumberOfQuestions !== '' &&
                                    (Number(newNumberOfQuestions) < 1 ||
                                      Number(newNumberOfQuestions) > 50)
                                  }
                                />
                              ) : (
                                moduleDetails[moduleData.module_id]
                                  ?.numberOfQuestions || 'N/A'
                              )}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <b>Passing Score</b>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              {editModuleId === moduleData.module_id ? (
                                <Form.Control
                                  type="number"
                                  value={newPassingScore}
                                  min="0"
                                  max="100"
                                  onChange={(e) =>
                                    setNewPassingScore(e.target.value)
                                  }
                                  isInvalid={
                                    newPassingScore !== '' &&
                                    (Number(newPassingScore) < 0 ||
                                      Number(newPassingScore) > 100)
                                  }
                                />
                              ) : moduleDetails[moduleData.module_id]
                                  ?.passingScore ? (
                                `${
                                  moduleDetails[moduleData.module_id]
                                    .passingScore
                                }%`
                              ) : (
                                'N/A'
                              )}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div className="edit-container">
                                {editModuleId === moduleData.module_id ? (
                                  <button
                                    className="save-button"
                                    onClick={handleSaveChanges}
                                  >
                                    Save
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="edit-button"
                                    onClick={() =>
                                      handleEditClick(
                                        moduleData.module_id,
                                        moduleDetails[moduleData.module_id]
                                          ?.numberOfQuestions || '',
                                        moduleDetails[moduleData.module_id]
                                          ?.passingScore || ''
                                      )
                                    }
                                  >
                                    Edit
                                  </button>
                                )}
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                )
              )}
              {Object.keys(courseContent).length > 0 && (
                <Accordion.Item className="pt-3 pe-3 pb-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="delete-button"
                    onClick={() => deleteContent(course.id)}
                  >
                    Delete Content
                  </button>
                </Accordion.Item>
              )}
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentModal;
